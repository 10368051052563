import React, { useEffect } from "react";
import Button from "../buttons/Button";

const OrderInfoCard = ({
  orderID,
  orderStatus,
  dateTime,
  outletAddress,
  orderType,
  deliveryAddress,
  deliveryLocations,
  selectedDeliveryLocation,
}) => {
  console.log("deliveryAddress", deliveryAddress);
  return (
    <tr>
      <td colSpan={2}>
        <div className="orderdetail-info-card">
          <div className="col-1">
            <h4>#{orderID}</h4>
            <p style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/icons/order-time.png"
                alt="time"
                style={{ marginRight: "0.5rem" }}
              />
              {dateTime}
            </p>
            {orderType === "pickup" && (
              <p style={{ display: "flex", alignItems: "start" }}>
                <img
                  src="/images/icons/order-location.png"
                  alt="location"
                  style={{ marginRight: "0.5rem" }}
                />
                {"Pick-up address : " + outletAddress}
              </p>
            )}
            {orderType === "delivery" && (
              <p style={{ display: "flex", alignItems: "start" }}>
                <img
                  src="/images/icons/order-location.png"
                  alt="location"
                  style={{ marginRight: "0.5rem" }}
                />
                {
                  deliveryAddress &&
                    "Delivery address: " +
                      deliveryAddress.house +
                      ", " +
                      deliveryAddress.road +
                      ", " +
                      deliveryAddress.sector +
                      ", "
                  // +
                  // deliveryAddress.area
                  // ? deliveryAddress.area
                  // : deliveryLocations[selectedDeliveryLocation].name
                }
              </p>
            )}
          </div>
          <div className="col-2">
            <span className={`status status-order-${orderStatus}`}>
              {orderStatus}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default OrderInfoCard;
