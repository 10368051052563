import React from "react";

const CartHeader = ({
  items,
  item_index,
  handleOnclickChecked,
  handleOnclickUnChecked,
}) => {
  return (
    <tr
      style={{
        borderTop: "0.5px solid rgba(171, 148, 109, 0.5)",
      }}
    >
      <td colSpan={3}>
        <div>
          <input
            type="checkbox"
            checked={
              items.variation.filter((variation) => variation.qty > 0).length >
              0
                ? true
                : false
            }
            onChange={() => {}}
            onClick={() => {
              items.variation.filter((variation) => variation.qty > 0).length >
              0
                ? handleOnclickChecked(item_index)
                : handleOnclickUnChecked(item_index);
            }}
            className="check"
          />
          <h4
            className={`${
              items.variation.filter((variation) => variation.qty > 0)
                .length === 0
                ? "disabled"
                : ""
            } cart-item-label`}
          >
            {items.name}
          </h4>
        </div>
      </td>
    </tr>
  );
};

export default CartHeader;
