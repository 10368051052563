import React from "react";

const CheckoutHeadings = ({ title, itemName }) => {
  return (
    <tr>
      <td colSpan={2}>
        <h2
          style={{
            fontSize: "clamp(1rem,2vw,1.25rem)",
            color: "rgba(220,215,207,0.5)",
            fontWeight: "500",
            paddingBottom: "1rem",
            margin: "0",
          }}
        >
          {title}
        </h2>
        <h3
          style={{
            fontSize: "clamp(1rem,1vw,1.25rem)",
            color: "rgba(220,215,207,1)",
            fontWeight: "500",
            paddingBottom: "1rem",
            margin: "0",
          }}
          className="text-light"
        >
          {itemName}
        </h3>
      </td>
    </tr>
  );
};

export default CheckoutHeadings;
