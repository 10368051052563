import React from "react";

const OrderAddons = ({ index, addon }) => {
  return (
    <tr key={index}>
      <td>
        <span className="bulletpoint"></span>
        <span className="orderDetailSub-item-label">
          {addon.name} (x{addon.qty})
        </span>
      </td>
      <td>
        <span className="checkout-item-label">
          Tk.{addon.price * addon.qty}
        </span>
      </td>
    </tr>
  );
};

export default OrderAddons;
