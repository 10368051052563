import React from "react";
import Slider from "react-slick";

const OutletCarousel = ({ images }) => {
  // console.log("outlet images url:", images, "image::", images.length);
  return (
    <Slider {...settings} className="">
      {images.length > 0 ? (
        images.map((images, idx) => (
          <div key={idx} className="tab-carousel">
            <img src={`/${images}`} alt={"images"} />
          </div>
        ))
      ) : (
        <div>No images</div>
      )}
    </Slider>
  );
};

export default OutletCarousel;

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 1,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 5000,
  draggable: true,
};
