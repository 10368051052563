import React from "react";
import Button from "../buttons/Button";
import { useNavigate } from "react-router-dom";
import "./payment.css";

const FailedPayment = () => {
  const navigate = useNavigate();
  return (
    <div className="payment container">
      <h1>Payment failed</h1>
      <p>We couldn't complete the payment. Please try again.</p>
      <Button
        onClick={() => {
          navigate("/checkout");
        }}
        buttonText={"Try Again"}
        buttonType="contained"
      />
    </div>
  );
};

export default FailedPayment;
