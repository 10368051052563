import React from "react";

const CartVariationItem = ({
  variation,
  variation_index,
  item_index,
  decreaseVariationQty,
  increaseVariationQty,
}) => {
 

  return (
    <tr>
      <td className={`${variation.qty === 0 ? "disabled" : ""}  `}>
        <div className="cart-sub-item-label">
          <span className="bulletpoint"></span>
          {variation.name}
        </div>
      </td>
      <td>
        {" "}
        <div
          className="middle"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <button
            className="minus"
            onClick={() => {
              if (variation.qty > 0) {
                decreaseVariationQty(item_index, variation_index);
              }
            }}
          >
            -
          </button>
          <input
            type="text"
            className="quantity"
            value={variation.qty} //item quantity
            onChange={() => {}}
          ></input>
          <button
            className="plus"
            onClick={() => {
              increaseVariationQty(item_index, variation_index);
            }}
          >
            +
          </button>
        </div>
      </td>
      <td>
        <div className="cart-price-label">
          TK.{variation.price * variation.qty}
        </div>
      </td>
    </tr>
  );
};

export default CartVariationItem;
