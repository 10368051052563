import axios from "axios";

const api = axios.create({
  // baseURL: "https://dev.gloriajeanscoffeesbd.com/api/",
  baseURL: "https://gloriajeanscoffeesbd.com/api/",
  // baseURL: "https://159.89.82.117:8000/api",
  // baseURL: "http://localhost:5000/api/",
  // baseURL: "https://foods-gjc2023-gjc-server.vercel.app/api/",
  // baseURL: "http://66.29.134.125:5000/api/",
});

api.interceptors.request.use(async function (config) {
  const token = localStorage.getItem("token");

  config.headers["x-access-token"] = token;
  return config;
});
export default api;
