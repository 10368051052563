import React from "react";

const OrderVariation = ({ variation, variation_index }) => {
  return (
    <tr key={variation_index}>
      <td>
        <span className="bulletpoint"></span>
        <span className="orderDetailSub-item-label">
          {variation.name} (x{variation.qty})
        </span>
      </td>
      <td>
        <span className="checkout-item-label">
          Tk.{variation.price * variation.qty}
        </span>
      </td>
    </tr>
  );
};

export default OrderVariation;
