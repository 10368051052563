import React, { useState, useMemo } from "react";
import Button from "../buttons/Button";
import { useNavigate } from "react-router-dom";
import api from "../../api/axios";
import { useAppContext } from "../../global/gjcContext";

const CheckoutActionButtons = () => {
  const [loading, setloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const {
    orderType,
    setPayment,
    deliveryAddressInfo,
    setDeliveryAddressInfo,
    deliveryLocations,
    selectedDeliveryLocation,
    // setSingleOutlet,
    currentUser,
    saveThisAddress,

    setCurrentUser,
  } = useAppContext();
  let delivaryAreaCode =
    deliveryLocations && deliveryLocations[selectedDeliveryLocation]._id;

  let delivaryOutlet =
    deliveryLocations && deliveryLocations[selectedDeliveryLocation];

  const savedAddress = useMemo(
    () =>
      currentUser &&
      currentUser.savedAddress.filter(
        (address) => address.area === delivaryAreaCode
      ),
    [currentUser.savedAddress, delivaryAreaCode]
  );
  const createOrder = async (e) => {
    // if (orderType === "delivery") {
    //   setSingleOutlet(delivaryOutlet);
    //   localStorage.setItem("singleOutlet", JSON.stringify(delivaryOutlet));
    // }

    if (savedAddress.length > 0) {
      setDeliveryAddressInfo({
        ...deliveryAddressInfo,
        //  area: delivaryAreaCode,
      });
    } else {
      setDeliveryAddressInfo({
        ...deliveryAddressInfo,
        area: delivaryAreaCode,
      });
    }

    try {
      setloading(true);
      const response = await api
        .post("/get-ssl-session", {
          total_amount: "1500",
          currency: "BDT",
          tran_id: "<a unique transaction id>",
          success_url:
            "https://foods-gjc2023-gjc-server.vercel.app/api/success_url_web",
          fail_url:
            "https://foods-gjc2023-gjc-server.vercel.app/api/fail_url_web",
          cancel_url:
            "<where the the api will post data if the request is canceled>",
          ipn_url: "https://foods-gjc2023-gjc-server.vercel.app/api/validate",
          cus_name: "<customer name>",
          cus_email: "cust@yahoo.com",
          cus_add1: "Dhaka",
          cus_add2: "Dhaka",
          cus_city: "Dhaka",
          cus_state: "Dhaka",
          cus_postcode: "1000",
          cus_country: "Bangladesh",
          cus_phone: "01711111111",
          cus_fax: "01711111111",
          ship_name: "Customer Name",
          ship_add1: "Dhaka",
          ship_add2: "Dhaka",
          ship_city: "Dhaka",
          ship_state: "Dhaka",
          ship_postcode: "1000",
          ship_country: "Bangladesh",
          multi_card_name: "mastercard,visacard,amexcard",
          value_a: "ref001_A",
          value_b: "ref002_B",
          value_c: "ref003_C",
          value_d: "ref004_D",
          shipping_method: "YES",
          product_name: "credit",
          product_category: "general",
          product_profile: "general",
        })
        .then(async (resp) => {
          // window.location.replace(resp.data.gatewayPageUrl);

          // create order

          setPayment(0);

          if (resp.status === 200) {
            navigate("/payment", {
              state: {
                data: resp.data,
              },
            });
          }

          setloading(false);

          // Linking.openURL(resp.data.GatewayPageURL);
        });
    } catch (error) {
      alert(error);
    }
  };
  // filter deliveryaddressinfo object if any empty keys and value except direction and find length

  const isEmpty = Object.keys(deliveryAddressInfo).filter(
    (key) =>
      deliveryAddressInfo[key] === "" &&
      key !== "direction" &&
      key !== "area" &&
      key !== "name"
  ).length;

  const getUser = async () => {
    const response = await api.put("/updateProfile");

    if (response.data.success === true) {
      setCurrentUser(response.data.user);
      localStorage.setItem("userData", JSON.stringify(response.data.user));
    }
  };
  const saveAddress = async () => {
    try {
      const response = await api.post("/user/address", {
        savedAddress: {
          name: "New Address",
          house: deliveryAddressInfo.house,
          road: deliveryAddressInfo.road,
          sector: deliveryAddressInfo.sector,
          area:
            savedAddress.length > 0
              ? deliveryAddressInfo.area
              : delivaryAreaCode,
          direction: deliveryAddressInfo.direction,
        },
      });

      if (response.data.success === true) {
        setSuccess(true);
        setError(false);
        getUser();
      } else {
        setError(response.data);
        setSuccess(false);
      }
    } catch (err) {
      setError(err);
      alert(err);
    }
  };
  return (
    <tr>
      <td colSpan={2}>
        <div className="checkout-action-buttons">
          <Button
            onClick={() => {
              navigate.push("/cart");
            }}
            buttonText={"Go Back"}
            buttonType="outlined"
          />
          <Button
            submitType={"submit"}
            buttonText={"Place Order"}
            buttonType="contained"
            onClick={(e) => {
              e.preventDefault();
              if (saveThisAddress) {
                saveAddress();
              }
              if (isEmpty > 0 && orderType === "delivery") {
                alert("Please fill up all the fields");
              } else {
                createOrder();
              }
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default CheckoutActionButtons;
