import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { animationContainer, fadeInUp } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const Slide = ({ title, subTitle, imgUrl, index, totalSlides }) => {
  const slideAnimation = useAnimateOnInView();

  const width = 100 / totalSlides;
  const progess = index * width;

  const fadeInRight = {
    hidden: {
      opacity: 0,
      x: 80,
    },
    visible: {
      opacity: 0.75,
      x: 0,
      transition: {
        duration: 1.2,
      },
    },
  };

  return (
    <motion.div
      ref={slideAnimation.ref}
      animate={slideAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className="slide"
    >
      <motion.img
        src={imgUrl}
        alt={title.line1 + title.line2 + title.line3}
        variants={fadeInRight}
        className="slide-img"
      />
      <div className="content slide-content">
        <h1>
          <motion.span variants={fadeInUp}>{title.line1}</motion.span>
          <motion.span variants={fadeInUp} className="highlighted">
            {title.line2}
          </motion.span>
          <motion.span variants={fadeInUp}>{title.line3}</motion.span>
        </h1>
        <motion.p variants={fadeInUp} className="hero-subtitle">
          {subTitle}
        </motion.p>
        <Link to="/menu">
          <motion.button variants={fadeInUp} className="primary">
            View Menu
          </motion.button>
        </Link>
        {/* <div className="pagination">
          <h3>{index + 1}</h3>
          <div className="progress">
            <div
              className="bar"
              style={{ width: width + "%", left: progess + "%" }}
            ></div>
          </div>
          <h3>{totalSlides}</h3>
        </div> */}
      </div>
    </motion.div>
  );
};

export default Slide;
