import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import api from "../../api/axios";
import { OurGjcContext, useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";

const Login = () => {
  const [phone, setPhone] = useState();
  const [pwd, setPwd] = useState();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [navigatePath, setNavigatePath] = useState("/profile");

  const { setisLoggedIn, setCurrentUser } = useContext(OurGjcContext);

  const navigate = useNavigate();
  const prevLocation = useLocation();

  useEffect(() => {
    if (!prevLocation.state) {
      //
    } else {
      setNavigatePath(prevLocation.state.prevLocation);
    }
  }, [prevLocation]);

  const userLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post("/login", {
        phone: phone,
        password: pwd,
      });
      setCurrentUser(response.data.user);
      setisLoggedIn(true);

      localStorage.setItem("isLoggedIn", true);

      localStorage.setItem("userData", JSON.stringify(response.data.user));

      // Token works
      if (response.data) {
        localStorage.setItem("token", response.data.token);
        navigate(navigatePath, {});
      } else {
        alert(response.data);
      }
      // Token works

      // if (response.data) {

      // let token = response.headers["set-cookie"];
      // token = token[0].split("=");
      // token = token[1];
      // token = token.split(";");
      // token = token[0];

      // await localStorage.setItem("token", token).then(() => {

      // });
      // } else {
      // alert(response.data);
      // }
    } catch (err) {
      console.log(err.message);
      // setError(err.response.data);
      setSuccess(false);
    }
  };
  // importing from context
  const { isLoggedIn, currentUser } = useAppContext();

  // if (isLoggedIn) {
  //     return <Redirect to="/profile"/>;
  // }

  // else{
  // return <Redirect to='/login' />
  // }

  return (
    <div className="auth">
      <div className="content">
        <div className="auth-form">
          <h1>Log In</h1>
          <p>Enter your phone number & verify to continue.</p>
          <form className="primary" onSubmit={userLogin}>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              onChange={(e) => setPhone(e.target.value)}
            ></input>
            <input
              type="password"
              id="pwd"
              name="pwd"
              placeholder="Password"
              onChange={(e) => setPwd(e.target.value)}
            ></input>
            <button type="submit" className="primary">
              Log In
            </button>
          </form>
          <p className="alt-prompt">
            Don’t have an account?{" "}
            <Link to="/register" state={{ prevLocation: navigatePath }}>
              <span>
                <b>Register</b>
              </span>
            </Link>
          </p>
          {error && <p className="error">{error}</p>} {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Login;
