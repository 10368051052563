import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAppContext } from "../../global/gjcContext";

export const PrivateRoute = ({ children, prevLocation }) => {
  const { isLoggedIn } = useAppContext();

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ prevLocation: prevLocation }} />;
  }

  return children;
  // <>
  //   {(props) => {
  //     return isLoggedIn ? (
  //       <Component {...props} />
  //     ) : (
  //       <Navigate to="/login" replace={true} />
  //     );
  //   }}
  // </>
};
