import React from "react";
import "../../checkout/checkout.css";
import OrderContainer from "./order-container";
import MetaData from "../../layouts/MetaData";

const OngoingOrders = () => {
  return (
    <>
      <MetaData title={"Orders"} />
      <div className="checkout container">
        <div className="checkout-grid-2 content">
          <div className="col-1">
            <div className="checkout-details-wrapper">
              <OrderContainer />
            </div>
          </div>
          <div className="col-2">
            <div className="checkout-image-wrapper">
              <img
                style={{ maxWidth: "80%" }}
                src="/images/orders.svg"
                alt="checkout-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingOrders;
