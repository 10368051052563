import React, { useEffect } from "react";
import Scrollspy from "react-scrollspy";
import "./ScrollStyle.css";
import "./FoodHeader.css";

const FoodContent = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Menu - Gloria Jean's Coffees Bangladesh`;
  });

  return (
    <>
      {/* <h2 className="text-center head-text">
        <span>FOOD</span> MENU
      </h2> */}
      <nav className="nav-float">
        <Scrollspy
          className="scrollspy category-menu"
          items={[
            "BREAKFAST",
            "BREAKFAST_ADD_ON",
            "SANDWICHES",
            "BURGERS",
            "STARTERS",
            "SIDES",
            "SALADS",
            "PASTAS",
            "MAINS",
            "ADD_ONS",
            "PIZZAS",
            "ADD_ON_PIZZA",
            "CHEFS_SPECIAL",
            // "Add_On_for_Oceania",
            "Hot_Drinks",
            "Cold_Drinks",
            // "Product",
            // "Desserts",
            // "Muffins",
            // "Whole_Cake",
          ]}
          currentClassName="isCurrent"
        >
          <li>
            <a href="#BREAKFAST">BREAKFAST</a>
          </li>
          <li>
            <a href="#BREAKFAST_ADD_ON">BREAKFAST ADD ON</a>
          </li>
          <li>
            <a href="#SANDWICHES">SANDWICHES</a>
          </li>
          <li>
            <a href="#BURGERS">BURGERS</a>
          </li>
          <li>
            <a href="#STARTERS">STARTERS</a>
          </li>
          <li>
            <a href="#SIDES">SIDES</a>
          </li>
          <li>
            <a href="#SALADS">SALADS</a>
          </li>
          <li>
            <a href="#PASTAS">PASTAS</a>
          </li>
          <li>
            <a href="#MAINS">MAINS</a>
          </li>
          <li>
            <a href="#ADD_ONS">ADD ONS</a>
          </li>
          <li>
            <a href="#PIZZAS">PIZZAS</a>
          </li>
          <li>
            <a href="#ADD_ON_PIZZA">ADD ON - PIZZA</a>
          </li>
          <li>
            <a href="#CHEFS_SPECIAL">CHEF'S SPECIAL</a>
          </li>
          {/* <li>
            <a href="#Add_On_for_Oceania">Add On for Oceania</a>
          </li> */}
          <li>
            <a href="#Hot_Drinks">Hot Drinks</a>
          </li>
          <li>
            <a href="#Cold_Drinks">Cold Drinks</a>
          </li>
          {/* <li>
            <a href="#Product">Product</a>
          </li>
          <li>
            <a href="#Desserts">Desserts</a>
          </li>
          <li>
            <a href="#Muffins">Muffins</a>
          </li>
          <li>
            <a href="#Whole_Cake">Whole Cake</a>
          </li> */}
        </Scrollspy>
      </nav>
      <div className="content">
        <section className="foodMain">
          <h3 id="BREAKFAST">
            BREAKFAST <span>(ALL DAY)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>MORNING SIGNATURE</h4>
                  <div className="price">TK. 480</div>
                </div>
                <p>
                  Grilled chicken sausage, beef rashers, fried eggs in grilled
                  bell pepper, drizzled with mustard mayo, feta & 2 slices of
                  garlic bread.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>CHICKEN MORTADELLA WITH CROISSANT</h4>
                  <div className="price">TK. 550</div>
                </div>
                <p>
                  Sliced chicken mortadella, tomato, cheddar cheese & green
                  pesto mayo served with GJC’s spicy potatoes, mushroom & sunny
                  side up egg.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>BREAKFAST QUESADILLA</h4>
                  <div className="price">TK. 750</div>
                </div>
                <p>
                  Pita filled with beef rashers, smoked chicken, capsicum, feta,
                  tomato, jalapeno, mustard mayo served with Greek salad &
                  scrambled egg.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>BIG BREAKFAST (WITH COFFEE)</h4>
                  <div className="price">TK. 800</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>BIG BREAKFAST</h4>
                  <div className="price">TK. 650</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>VANILLA PANCAKE WITH BLUEBERRY COULIS</h4>
                  <div className="price">TK. 550</div>
                </div>
                <p>
                  Pancakes served blueberry coulis & scoops of vanilla
                  ice-cream.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* BREAKFAST ADD ON */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="BREAKFAST_ADD_ON">BREAKFAST ADD ON</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>BEEF RASHER (50gm)</h4>
                  <div className="price">TK. 200</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN SAUSAGE <span>(1pc)</span>
                  </h4>
                  <div className="price">TK. 175</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SCRAMBLED EGG <span>(2 eggs)</span>
                  </h4>
                  <div className="price">TK. 95</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SUNNY-SIDE-UP <span>(1 Egg)</span>
                  </h4>
                  <div className="price">TK. 45</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BAKED BEAN <span>(40 gm)</span>
                  </h4>
                  <div className="price">TK. 85</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    MUSHROOM <span>(80 gm)</span>
                  </h4>
                  <div className="price">TK. 80</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    MILK ROLL <span>(3 slices)</span>
                  </h4>
                  <div className="price">TK. 35</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BUTTER SACHET <span>(1pc)</span>
                  </h4>
                  <div className="price">TK. 50</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    ICE CREAM <span>(1 scoop)</span>
                  </h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    VANILLA PAN CAKE <span>(1pc)</span>
                  </h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BLUEBERRY COULIS <span>(40 gm)</span>
                  </h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* SANDWICHES */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="SANDWICHES">
            SANDWICHES <span>(ALL DAY)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN PARMIGIANA* <span>(Panini bread)</span>
                  </h4>
                  <div className="price">TK. 720</div>
                </div>
                <p>
                  Crumbed Chicken breast topped with napolitana, beef rasher,
                  smoked chicken & mozzarella, lettuce, tomato jalapeno & GJC’s
                  mustard mayo.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SMOKED CHICKEN SANDWICH <span>(Brown bread)</span>
                  </h4>
                  <div className="price">TK. 670</div>
                </div>
                <p>
                  Smoked chicken, capsicum, iceberg, onion, tomato, gherkin,
                  cheese & mayonnaise.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GRILLED CHICKEN SANDWICH* <span>(Panini bread)</span>
                  </h4>
                  <div className="price">TK. 670</div>
                </div>
                <p>
                  Grilled chicken, capsicum, iceberg, onion, cheese &
                  mayonnaise.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GRILLED VEGETABLE SANDWICH <span>(Panini bread)</span>
                  </h4>
                  <div className="price">TK. 550</div>
                </div>
                <p>
                  Grilled chicken, capsicum, iceberg, onion, cheese &
                  mayonnaise.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CLUB SANDWICH <span>(Brown bread)</span>
                  </h4>
                  <div className="price">TK. 720</div>
                </div>
                <p>
                  3 layered sandwich filled with smoked chicken, beef rasher,
                  fried egg, vegetables, cheese & mayonnaise.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* BURGERS */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="BURGERS">
            BURGERS <span>(FROM 11:00 AM)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SURF & TURF BURGER* <span>(Bun less)</span>
                    <img
                      src="./Assets/img/food/keto-logo.jpg"
                      width="50"
                      alt="Keto Logo"
                    />
                  </h4>
                  <div className="price">TK. 780</div>
                </div>
                <p>
                  Iceberg lettuce, tomato, chicken & beef patty, pineapple,
                  sliced cheese, gherkin, fried egg, caramelized onion grilled
                  shell prawn with chili garlic mayo.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN STEAK BURGER{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                    <span>(Panini bun)</span>
                  </h4>
                  <div className="price">TK. 600</div>
                </div>
                <p>
                  Spicy grilled chicken breast, tomato, jalapeno, lettuce,
                  sliced cheese & chili garlic mayo served with French fries.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN/BEEF PATTY BURGER <span>(Burger bread)</span>
                  </h4>
                  <div className="price">TK. 550</div>
                </div>
                <p>
                  Grilled patty of your choice, lettuce, tomato, jalapeno,
                  sliced cheese & BBQ mayonnaise served with French fries.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* Starters */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="STARTERS">
            STARTERS <span>(FROM 11:00 AM)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GARLIC MUSHROOM WITH SOY{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">TK. 430</div>
                </div>
                <p>
                  Mushrooms tossed with garlic, chili flakes, herbs, served with
                  garlic bread & chill soy.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    THAI LEMON CHICKEN LOLLY <span>(5 pcs)</span>
                  </h4>
                  <div className="price">TK. 370</div>
                </div>
                <p>
                  Deep fried home made Thai lemon chicken served with coleslaw.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN BUFFALO WINGS <span>(6 pcs)</span>
                  </h4>
                  <div className="price">TK. 390</div>
                </div>
                <p>
                  Spicy coated & golden fried buffalo wings, served with GJC’s
                  chili garlic mayo.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    STEAMED CHICKEN MOMO <span>(8 pcs)</span>
                  </h4>
                  <div className="price">TK. 390</div>
                </div>
                <p>
                  Stuffed with minced chicken, coriander, red chilies & served
                  with chili soy.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>FRIED CALAMARI RINGS</h4>
                  <div className="price">TK. 750</div>
                </div>
                <p>
                  Crumbed, golden fried Australian calamari rings, served with
                  tartar sauce & French fries.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BASIL PRAWN ROLLS <span>(8 pcs)</span>
                  </h4>
                  <div className="price">TK. 470</div>
                </div>
                <p>
                  Pilled river prawns wrapped with basil pesto & parmesan,
                  served with sweet chili sauce.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* sides */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="SIDES">
            SIDES <span>(FROM 11:00 AM)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>FRENCH FRIES*</h4>
                  <div className="price">TK. 230</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>POTATO WEDGES</h4>
                  <div className="price">TK. 320</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>STIR - FRY VEGETABLES</h4>
                  <div className="price">TK. 320</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* salads */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="SALADS">
            SALADS <span>(FROM 11:00 AM)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    PROTOS SALAD{" "}
                    <img src="./Assets/img/food/keto-logo.jpg" width="50" />
                  </h4>
                  <div className="price">TK. 600</div>
                </div>
                <p>
                  Iceberg lettuce, mustard chicken, orange, grape, almond
                  flakes, feta, green capsicum, roasted beetroot, accompanied
                  with chili garlic mayo.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    OCEAN SALAD{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">TK. 790</div>
                </div>
                <p>
                  Selection of freshly blanched seafood, with fresh iceberg.
                  tomato, onion, capsicum and spicy Asian dressing.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>RAINBOW SALAD</h4>
                  <div className="price">TK. 550</div>
                </div>
                <p>
                  Local lettuce, spicy grilled chicken, olive, tomato, cucumber.
                  green capsicum, onion, orange & accompanied with honey-lemon
                  dressing.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* salads */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="PASTAS">
            PASTAS <span>(FROM 12:00 AM)</span>
            <span>(Served with garlic bread)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GREEK VEGETARIAN{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">TK. 600</div>
                </div>
                <p>
                  Fettuccini cooked with tomato, garlic, green capsicum, feta,
                  oregano, olive, red chili, drizzled by Greek-lemon dressing.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>BEEF LASAGNA*</h4>
                  <div className="price">TK. 815</div>
                </div>
                <p>
                  Five layered pasta with GJC’s meat sauce, mozzarella cheese,
                  topped with béchamel sauce & comes with French fries.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>FETTUCCINI ALFREDO*</h4>
                  <div className="price">TK. 950</div>
                </div>
                <p>
                  Grilled chicken cubes, sliced mushroom finished with rich
                  cream sauce.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SPAGHETTI MARINARA{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">TK. 950</div>
                </div>
                <p>
                  Spaghetti cooked with river prawns, calamari, dory, tomato &
                  chili flakes in napolitana.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* mains */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="MAINS">
            MAINS <span>(FROM 12:00 AM)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN SCALLOPINI{" "}
                    <img src="./Assets/img/food/keto-logo.jpg" width="50" />
                  </h4>
                  <div className="price">TK. 750</div>
                </div>
                <p>
                  Chicken breast cooked in rich creamy mushroom sauce, served
                  with seasonal vegetables & roasted potatoes.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN PICASSO{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">TK. 650</div>
                </div>
                <p>
                  Spicy grilled chicken breast served with green beans, roasted
                  potatoes, topped with olive, pepper & balsamic reduction.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>BEEF SERLOIN STEAK</h4>
                  <div className="price">TK. 1700</div>
                </div>
                <p>
                  Sirloin steak (250 gm) served with roasted potatoes. sauteed
                  vegetables & pepper-mushroom sauce, drizzled with balsamic
                  reduction.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GRILLED DORY <span>(Served with garlic rice)</span>
                  </h4>
                  <div className="price">TK. 765</div>
                </div>
                <p>
                  Crumbed and grilled fish fillet, accompanied with mustard
                  potato salad & vegetables, topped with GJC’s mustard mayo.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>SURF & TURF</h4>
                  <div className="price">TK. 1750</div>
                </div>
                <p>
                  Minute beef steak, spicy grilled chicken breast, dory fillet,
                  grilled shell prawn, accompanied with grilled mushrooms,
                  pineapple & tomato, garden salad, crushed potatoes & Italian
                  creamy chesse sauce.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    LATINO CHICKEN <span>(Served with Mexican rice)</span>
                  </h4>
                  <div className="price">TK. 700</div>
                </div>
                <p>
                  Chicken breast marinated with yellow mustard, grilled.
                  pineapple with homemade spicy tomato concasse.
                </p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/* add ons */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="ADD_ONS">MAINS ADD ONS</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>BEEF / CHICKEN PATTY</h4>
                  <div className="price">TK. 155</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Chicken Cubes <span>(50 gm)</span>
                  </h4>
                  <div className="price">TK. 95</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Sea food <span>(50 gm)</span>
                  </h4>
                  <div className="price">TK. 145</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Prawn <span>(20 gm)</span>
                  </h4>
                  <div className="price">TK. 110</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Steam rice</h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Slice cheese/feta</h4>
                  <div className="price">TK. 45</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              {/* <p>*SD applicable per Govt. law.</p> */}
            </div>
          </div>
        </section>
        {/* pazzas */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="PIZZAS">
            PIZZAS <span>(FROM 12:00 PM)(Real thin crust)</span>
          </h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BEEF HAWAIIAN PIZZA*{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 825 <span>SMALL</span>
                    <p>Add beef salami</p>
                  </div>
                  <div className="price">
                    TK. 1550 <span>LARGE</span>
                    <p>TK. 120 (S) | TK 180 (R)</p>
                  </div>
                </div>
                <p>
                  Spicy beef salaml, beef pepperoni, pineapple, black olive,
                  jalapeno, chili flakes.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    SPICY BEEF*{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 750 <span>SMALL</span>
                    <p>Add beef</p>
                  </div>
                  <div className="price">
                    TK. 1400 <span>LARGE</span>
                    <p>TK. 120 (S) | TK 180 (R)</p>
                  </div>
                </div>
                <p>
                  Slow cooked beef, red capsicum, jalapeno, spring onion, dry
                  red chilli.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    OCEAN GROVE*{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 750 <span>SMALL</span>
                    <p>Add seafood</p>
                  </div>
                  <div className="price">
                    TK. 1400 <span>LARGE</span>
                    <p>TK. 150 (S) | TK 220 (R)</p>
                  </div>
                </div>
                <p>Tomato, prawn, calamari, spring onion, coriander, lemon.</p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    CHICKEN SUPREMO*{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 675 <span>SMALL</span>
                    <p>Add chicken salami</p>
                  </div>
                  <div className="price">
                    TK. 1200 <span>LARGE</span>
                    <p>TK. 100 (S) | TK 150 (R)</p>
                  </div>
                </div>
                <p>
                  Smoked chicken, spicy chicken salami, grilled chicken,
                  capsicum, onlon, chill flakes, Jalapeno & pineapple.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    TANDOORI CHICKEN *{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 650 <span>SMALL</span>
                    <p>Add chicken</p>
                  </div>
                  <div className="price">
                    TK. 1200 <span>LARGE</span>
                    <p>TK. 100 (S) | TK 150 (R)</p>
                  </div>
                </div>
                <p>
                  Tandoori spiced chicken cubes, tomato, onion, mint, green
                  chill, finished with garlic yoghurt sauce.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    BBQ CHICKEN *{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 650 <span>SMALL</span>
                    <p>Add chicken</p>
                  </div>
                  <div className="price">
                    TK. 1250 <span>LARGE</span>
                    <p>TK. 120 (S) | TK 180 (R)</p>
                  </div>
                </div>
                <p>
                  BBQ chicken cubes, smoky beef, pineapple, jalapeno, red chilli
                  flakes. onion, drizzled with homemade BBQ sauce.
                </p>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    MARGHERITA PIZZA*{" "}
                    <img
                      draggable="false"
                      role="img"
                      className="emoji"
                      width="20"
                      alt="🌶️"
                      src="https://s.w.org/images/core/emoji/13.1.0/svg/1f336.svg"
                    />
                  </h4>
                  <div className="price">
                    TK. 480 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 850 <span>LARGE</span>
                  </div>
                </div>
                <p>Napolitana, mozzarella, basil, dry oregano.</p>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>

        {/* pazzas add ons */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="ADD_ON_PIZZA">ADD ON - PIZZA</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>CHICKEN</h4>
                  <div className="price">
                    TK. 120 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 180 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>CHICKEN SALAMI</h4>
                  <div className="price">
                    TK. 120 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 180 <span>LARGE</span>
                  </div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>BEEF</h4>
                  <div className="price">
                    TK. 145 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 215 <span>LARGE</span>
                  </div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>BEEF SALAMI</h4>
                  <div className="price">
                    TK. 155 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 215 <span>LARGE</span>
                  </div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>SEAFOOD</h4>
                  <div className="price">
                    TK. 180 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 265 <span>LARGE</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>

        {/* chef's special */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="CHEFS_SPECIAL">CHEF'S SPECIAL</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>OCEANIA</h4>
                  <div className="price">
                    TK. 4000 <span>(300 gm+ LOBSTER)</span>
                  </div>
                  <div className="price">
                    TK. 4500 <span>(400 gm+ LOBSTER)</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4> Chocolate chip Pancake with icecream</h4>
                  <div className="price">TK. 500</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Beef Bourguignon</h4>
                  <div className="price">TK. 850</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Creamy Fettuccini Carbonara</h4>
                  <div className="price">TK. 950</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mexicana Beef Pizza</h4>
                  <div className="price">
                    TK. 825 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 1550 <span>LARGE</span>
                  </div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>Lemoyne Chicken Salad</h4>
                  <div className="price">TK. 600</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Portuguese Chicken Burger</h4>
                  <div className="price">TK. 600</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/*  Add On for Oceania */}
        {/* <br />
        <br />
        <section className="foodMain">
          <h3 id="Add_On_for_Oceania">Add On for Oceania</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Lobster- 300 <span>+ gm</span>{" "}
                  </h4>
                  <div className="price">
                    TK. 5 <span>Per gm</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Lobster- 400 <span>+ gm</span>{" "}
                  </h4>
                  <div className="price">
                    TK. 6 <span>Per gm</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Lobster- 500 <span>+ gm</span>{" "}
                  </h4>
                  <div className="price">
                    TK. 7 <span>Per gm</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section> */}
        {/*  Drinks from here */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="Hot_Drinks"> Hot Drinks</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Cappuccino <span>Classic Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 280 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 360 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Café Latte <span>Classic Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 280 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 360 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Esspresso <span>Classic Esp.</span>
                  </h4>
                  <div className="price">TK. 220</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Macchiato <span>Classic Esp.</span>
                  </h4>
                  <div className="price">TK. 220</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Café Americano <span>Classic Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 220 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 290 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Coffee of the Day <span>Classic Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 220 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 290 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Café Mocha <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 350 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 450 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Caramel latte <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 350 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 450 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Very Vanilla Latte <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 350 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 450 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Crème Burlee Latte <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 350 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 450 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Maccademia Latte <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 385 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Tiramisu Cappuccino <span>Classic Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 385 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    White Chocolate Mocha <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 355 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 445 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Caramelnut Cappuccino <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 375 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 460 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Irish Nut Crème <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 355 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 445 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hazelnut Mocha <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 375 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 460 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Toffee Nut Latte <span>Specialities Esp.</span>
                  </h4>
                  <div className="price">
                    TK. 385 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Chai Latte <span>Hot Tea</span>
                  </h4>
                  <div className="price">
                    TK. 295 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 350 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    vanilla Chai Latte <span>Hot Tea</span>
                  </h4>
                  <div className="price">
                    TK. 325 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 400 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Green tea Latte <span>Hot Tea</span>
                  </h4>
                  <div className="price">
                    TK. 295 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 350 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea (Pyramid) <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea (Pyramid)-green tea <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea - Vanilla Black Tea <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea Tropical Green <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea Rose Petal Green <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Tea Sweet Peppermint
                    <span>Hot Tea</span>
                  </h4>
                  <div className="price">TK. 210</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hot Chocolate <span>Hot Chocolate</span>
                  </h4>
                  <div className="price">
                    TK. 285 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 340 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    White Hot Chocolate <span>Hot Chocolate</span>
                  </h4>
                  <div className="price">
                    TK. 285 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 340 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    GJ's Creamy Hot Chocolate <span>Hot Chocolate</span>
                  </h4>
                  <div className="price">
                    TK. 335 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 365 <span>LARGE</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        {/*  Drinks cold from here */}
        <br />
        <br />
        <section className="foodMain">
          <h3 id="Cold_Drinks"> Cold Drinks</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Very Vanilla Chiller <span>Esp.Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 560 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Crème Brûlée <span>Esp.Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 560 <span>LARGE</span>
                  </div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>
                    Coco Loco <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 560 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Cookies n Cream <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 560 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    chocolate chip Chiller <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 560 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Hazelnut Mocha Chil <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 495 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 580 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Caramel Nut chiller <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 495 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 580 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Maccademia Chiller <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 520 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 640 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Tiramisu chiller <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 520 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 640 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Toffee Nut Chiller <span>Mocha Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 520 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 640 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Chocolate Delight <span>Gourmet Iced Choco</span>
                  </h4>
                  <div className="price">
                    TK. 440 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Original Iced Choc <span>Gourmet Iced Choco </span>
                  </h4>
                  <div className="price">
                    TK. 440 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 460 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    White Iced Choc <span>Gourmet Iced Choco</span>
                  </h4>
                  <div className="price">
                    TK. 440 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 460 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Strawberries n Cream <span>Gourmet Iced Choco</span>
                  </h4>
                  <div className="price">
                    TK. 480 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Mixedberry n cream <span>Gourmet Iced Choco</span>
                  </h4>
                  <div className="price">
                    TK. 480 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Lime <span>Fruit Chillers/over ice</span>
                  </h4>
                  <div className="price">
                    TK. 465 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Apple Chiller <span>Fruit Chillers</span>
                  </h4>
                  <div className="price">
                    TK. 465 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Green Tea Chiller <span>Espresso Chiller</span>
                  </h4>
                  <div className="price">
                    TK. 440 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 510 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    MATCHA SHAKE <span>Fusion</span>
                  </h4>
                  <div className="price">TK. 465</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    VANILA SHAKE <span>Fusion</span>
                  </h4>
                  <div className="price">TK. 410</div>
                </div>
              </div>

              <div className="foodInner">
                <div className="title">
                  <h4>
                    Chai Tea Chiller <span>chai tea hiller</span>
                  </h4>
                  <div className="price">
                    TK. 440 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Signature Iced Coffee <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 380 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 475 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Americano <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 320 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 350 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Sparkling americano <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 350 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 385 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Mocha <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 390 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 455 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Caramel Latte <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 480 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Lychee Cooler <span>Fusion</span>
                  </h4>
                  <div className="price">TK. 480</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Bluebeery Lemonade <span>lemonade</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Lyche Lamonade <span>lemonade</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Green Apple Lemonade <span>lemonade</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    peach Lemonade <span>lemonade</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 550 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Tea-peach <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 330 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 430 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Iced Tea-lemon lyche <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 330 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 430 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Apple Soda<span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 465 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Italian Soda(lime) <span>Over Ice</span>
                  </h4>
                  <div className="price">
                    TK. 465 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 495 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Mango,strawberry fruit smoothie <span>fruit smoothie</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Mixed berry fruit Smoothie <span>fruit smoothie</span>
                  </h4>
                  <div className="price">
                    TK. 450 <span>SMALL</span>
                  </div>
                  <div className="price">
                    TK. 480 <span>LARGE</span>
                  </div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Melon Smoothie <span>fruit smoothie</span>
                  </h4>
                  <div className="price">TK. 450</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Blueberry Yogurt Smoothe <span>fruit smoothie</span>
                  </h4>
                  <div className="price">TK. 520</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Apple Yogurt Smoothe <span>fruit smoothie</span>
                  </h4>
                  <div className="price">TK. 395</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Fresh Juice- Orange <span>Fruit Juice</span>
                  </h4>
                  <div className="price">TK. 300</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>
                    Fresh Juice- Apple <span>Fruit Juice</span>
                  </h4>
                  <div className="price">TK. 300</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>

        {/* dessert start from here */}
        {/* <br />
        <br />
        <section className="foodMain">
          <h3 id="Product">Product</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>Red Velvet Cheese Roll</h4>
                  <div className="price">TK. 395</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>carrot cake</h4>
                  <div className="price">TK. 353</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Flourless Orange cake</h4>
                  <div className="price">TK. 419</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Butterscotch Roll</h4>
                  <div className="price">TK. 299</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Chocolate Chip Cheese Cake</h4>
                  <div className="price">TK. 395</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mashmallow Cheese Cake</h4>
                  <div className="price">TK. 395</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Fancy Peanut Butter Cheese Cake</h4>
                  <div className="price">TK. 395</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Clasic Date Cheese terrine</h4>
                  <div className="price">TK. 419</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section> */}
        {/* dessert start from here */}
        {/* <br />
        <br />
        <section className="foodMain">
          <h3 id="Desserts">Desserts</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>Brownie</h4>
                  <div className="price">TK. 167</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Cheese Filling barliner</h4>
                  <div className="price">TK. 150</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mini Chocolate Chips Cookies</h4>
                  <div className="price">TK. 36</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mini Oat Cookies </h4>
                  <div className="price">TK. 36</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mini Chocolate Eclairs</h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mini Caramel Eclairs</h4>
                  <div className="price">TK. 84</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Friut Danish</h4>
                  <div className="price">TK. 146</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>date pudding</h4>
                  <div className="price">TK. 70</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>baked yoghurt</h4>
                  <div className="price">TK. 90</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section> */}
        {/* Muffins */}
        {/* <br />
        <br />
        <section className="foodMain">
          <h3 id="Muffins">Muffins</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>Red Velvet Muffin</h4>
                  <div className="price">TK. 208</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Banana Muffin</h4>
                  <div className="price">TK. 155</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Chocolate chip Muffin</h4>
                  <div className="price">TK. 208</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section> */}
        {/* Whole Cake */}
        {/* <br />
        <br />
        <section className="foodMain">
          <h3 id="Whole_Cake">Whole Cake</h3>
          <div className="foodMenu">
            <div className="foodContent">
              <div className="foodInner">
                <div className="title">
                  <h4>Blueb. Cheese Cake(Whole)</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Chocolate Fudge cake (Whole)</h4>
                  <div className="price">TK. 2392</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Italian Tiramissu (Whole)</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Oreo Cheese Cake (Whole)</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Red Velvet (Whole)</h4>
                  <div className="price">TK. 2392</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Straw. Cheese Cake (Whole)</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Tiamaria Cho. (Whole)</h4>
                  <div className="price">TK. 2392</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Carrot cake ( Whole )</h4>
                  <div className="price">TK. 2392</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Flourless Orange cake ( Whole )</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Chocolate chip Cheese Cake ( Whole)</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Mashmallow Cheese Cake ( Whole )</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
              <div className="foodInner">
                <div className="title">
                  <h4>Fancy Peanut Butter Cheese Cake ( Whole )</h4>
                  <div className="price">TK. 2751</div>
                </div>
              </div>
            </div>
            <div className="smallp">
              <p>
                Please note that, Prices are inclusive of all charges & taxes.{" "}
              </p>
              <p>*SD applicable per Govt. law.</p>
            </div>
          </div>
        </section>
        <br />
        <br /> */}
      </div>
    </>
  );
};

export default FoodContent;
