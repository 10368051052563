import React from "react";

const CartSubTotal = ({ SubTotal }) => {
  return (
    <tr style={{ borderTop: "0.5px solid rgba(171, 148, 109, 0.5)" }}>
      <td className="subtotal">subtotal</td>
      <td></td>
      <td className="subtotal ">TK.{SubTotal}</td>
    </tr>
  );
};

export default CartSubTotal;
