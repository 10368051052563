import React, { useState, useEffect, useMemo } from "react";
import OrderCard from "./orderCard";
import Loader from "../../layouts/Loader";
import api from "../../../api/axios";

const OrderContainer = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const today = new Date();
  const dateToday = today.toISOString().split("T")[0];
  const [dateInputs, setDateInputs] = useState({
    startDate: "",
    endDate: dateToday, // end date is today only date
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDateInputs({ ...dateInputs, [name]: value });
  };

  const getAllOrder = async () => {
    try {
      const response = await api.get("/user/order");
      // console.log("all order response:", response);
      setOrders(response.data);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const isAllEmpty = Object.values(dateInputs).every((x) => x === "");
  // filter orderlist
  useMemo(() => {
    if (isAllEmpty) {
      setFilteredOrders(orders);
    } else {
      setFilteredOrders(
        orders.filter((order) => {
          return (
            order.updatedAt.split("T")[0] >= dateInputs.startDate &&
            order.updatedAt.split("T")[0] <= dateInputs.endDate
          );
        })
      );
    }
  }, [dateInputs.endDate, dateInputs.startDate, orders, isAllEmpty]);

  useEffect(() => {
    getAllOrder();
  }, []);

  return (
    <>
      <div className="order-grid-2">
        <div className="col">
          {" "}
          <h2>
            <span className="highlighted">Order </span>History
          </h2>
        </div>
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <a
            onClick={() => {
              setDateInputs({ startDate: "", endDate: "" });
            }}
          >
            <span>Reset search</span>
          </a>
        </div>
      </div>

      <div
        className="order-grid-2"
        style={{
          background: "rgba(69, 74, 77, 0.25)",
          margin: "1rem 0",
          borderRadius: "10px",
          padding: "1rem 0",
        }}
      >
        <div
          className="col"
          style={{
            borderRight: "1px solid rgba(171, 148, 109, 0.5)",
            padding: "0 1rem",
          }}
        >
          <h3>
            <label htmlFor="startDate">Start Date</label>
          </h3>
          <input
            type="date"
            id="startDate"
            name="startDate"
            onChange={handleChange}
            value={dateInputs.startDate}
          />
        </div>
        <div className="col" style={{ padding: "0 1rem" }}>
          <h3>
            {" "}
            <label htmlFor="endDate">End Date</label>
          </h3>
          <input
            type="date"
            id="endDate"
            name="endDate"
            onChange={handleChange}
            value={dateInputs.endDate}
          />
        </div>
      </div>
      {!orders ? (
        <Loader />
      ) : // if orders is empty show loader
      orders.length && orders.length > 0 ? (
        <div className="order-grid-2">
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order, index) => (
              <div className="col" key={index}>
                <OrderCard
                  OrderID={order._id}
                  status={order.orderStatus}
                  updatedAt={order.updatedAt}
                  outlet={order.outlet && order.outlet.name}
                  totalAmount={order.total}
                />
              </div>
            ))
          ) : (
            <h2>No order yet</h2>
          )}
        </div>
      ) : (
        <h2>No order yet</h2>
      )}
    </>
  );
};

export default OrderContainer;
