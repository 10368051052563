import React, { useState, useEffect } from "react";
import Button from "../buttons/Button";
import api from "../../api/axios";
import { useNavigate } from "react-router-dom";

const OrderCancel = ({ orderID, orderStatus, onClose }) => {
  const [Words, setWords] = useState("");
  const [wordCount, setWordCount] = useState(0);

  const navigate = useNavigate();

  const countWord = () => {
    const words = Words.split(" ");
    setWordCount(words.length);
  };

  const handleChange = (e) => {
    setWords(e.target.value);
  };
  const handleCancelOrder = async () => {
    try {
      const response = await api.put("/updateOrderStatus", {
        _id: orderID,
        orderStatus: "Cancelled",
        paymentStatus: "RefundPending",
      });

      // console.log("response", response);
      window.location.reload();
    } catch (error) {
      console.log("order cant be cancelled", error);
    }
  };
  useEffect(() => {
    countWord();
    // console.log(Words);
    // console.log(wordCount);
    // console.log(orderID, orderStatus);
  }, [Words, wordCount]);
  return (
    <form
      onSubmit={() => {
        wordCount > 150 ? alert("Describe in 150 words.") : handleCancelOrder();
      }}
    >
      <div className="order-cancel">
        <h3>Order Cancel #2547u ?</h3>
        <p>Cancellation Reason</p>
        <input
          type="text"
          placeholder="Please describe within 150 words"
          onChange={handleChange}
          required
        />
        {wordCount > 150 ? (
          <p style={{ color: "rgba(250, 85, 85, 1)" }}>
            Describe in 150 words.
          </p>
        ) : (
          ""
        )}

        <div className="order-cancel-action">
          <Button buttonText={"No"} buttonType="outlined" onClick={onClose} />
          <Button
            submitType={"submit"}
            // onClick={() => {
            //   wordCount > 150
            //     ? alert("Describe in 150 words.")
            //     : handleCancelOrder();
            // }}
            buttonText={"Yes"}
            buttonType="contained"
          />
        </div>
      </div>
    </form>
  );
};

export default OrderCancel;
