import React, { useContext, useEffect, useState } from "react";
import api from "../../api/axios";
import "./UserProfile.css";
import { OurGjcContext } from "../../global/gjcContext";
import RemoveAddress from "./RemoveAddress";
import Modal from "../layouts/Modal";

const EditProfile = ({ changeEditMode, getUser }) => {
  const { isLoggedIn, setisLoggedIn, currentUser, setCurrentUser } =
    useContext(OurGjcContext);
  const [newName, setNewName] = useState();
  const [newEmail, setNewEmail] = useState();
  const [updating, setUpdating] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [addressId, setAddressId] = useState();
  const [addressIndex, setAddressIndex] = useState();
  const [updatedAddresses, setUpdatedAddresses] = useState(
    currentUser.savedAddress
  );

  // Address title onchange handler
  const updateAddressTitle = (e, index) => {
    updatedAddresses[index].name = e.target.value;
    setUpdatedAddresses(updatedAddresses);
  };

  // Address details onchange handler
  const updateAddressDetails = (e, index) => {
    updatedAddresses[index].address = e.target.value;
    setUpdatedAddresses(updatedAddresses);
  };

  // Update function
  const handleUpdate = async (e) => {
    // console.log("working from profile new");
    const params = {
      name: newName,
      email: newEmail,
      //  savedAddress: updatedAddresses,
    };

    try {
      setUpdating(true);
      await api.put("/updateProfile", params);
      getUser();
      setUpdating(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <div>
        <table className="infoTable">
          <td>
            <tr>
              <label>Name</label>
              <input
                placeholder={currentUser.name}
                onChange={(e) => setNewName(e.target.value)}
              ></input>
            </tr>
            <tr>
              <label>Email</label>
              <input
                placeholder={currentUser.email}
                onChange={(e) => setNewEmail(e.target.value)}
              ></input>
            </tr>

            {currentUser &&
              currentUser.savedAddress &&
              currentUser.savedAddress.map((address, index) => (
                <tr className="justified-between addressTable">
                  <td>
                    <tr>
                      <h3>Saved Addresses {index + 1}</h3>

                      {/* Delete Address Button */}

                      <button
                        className="deleteAddressButton"
                        onClick={() => {
                          setModalOpen(true);
                          // Passing address id to delete the specific address
                          setAddressId(address._id);
                          // Passing index to show the information of the clicked address delete
                          setAddressIndex(index);
                        }}
                      >
                        <img
                          src="/Assets/img/profile/icons/Delete.svg"
                          loading="lazy"
                          alt="AddButton"
                        />
                      </button>
                      {/* Remove Address Modal */}
                      <Modal
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        showButton={true}
                      >
                        <RemoveAddress
                          setModalOpen={setModalOpen}
                          addressId={addressId}
                          setAddressId={setAddressId}
                          getUser={getUser}
                          addressIndex={addressIndex}
                          setAddressIndex={setAddressIndex}
                          onClose={() => setModalOpen(false)}
                        />
                      </Modal>
                    </tr>
                    <tr className="addressDetails">
                      <tr>
                        <input
                          type="text"
                          placeholder={address.name}
                          onChange={(e) => updateAddressTitle(e, index)}
                        ></input>
                        <input
                          type="text"
                          placeholder={address.address}
                          onChange={(e) => updateAddressDetails(e, index)}
                        ></input>
                      </tr>
                    </tr>
                  </td>
                </tr>
              ))}
          </td>
        </table>
      </div>
      <div className="profileEditModeActionButtons">
        <button className="secondary" onClick={() => changeEditMode()}>
          Go Back
        </button>
        <button
          className="primary"
          onClick={() => {
            handleUpdate();
            changeEditMode();
          }}
        >
          {updating ? "Updating Info" : "Update"}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
