import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import Button from "../buttons/Button";
import api from "../../api/axios";
const DeleteAddress = ({ singleAddress, onClose, getUser }) => {
  const deleteAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await api.delete(`/user/address/${singleAddress._id}`);

      getUser();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        marginTop: "1rem",
        width: "clamp(250px, 50vw, 500px)",
      }}
      className="scrollable"
    >
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        Remove this address ?
      </h3>
      <label style={{ marginTop: "1.5rem" }}>{singleAddress.name}</label>
      <p>
        {singleAddress.house +
          ", " +
          singleAddress.road +
          ", " +
          singleAddress.sector}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button buttonText={"No"} buttonType={"outlined"} onClick={onClose} />
        <Button
          buttonText={"Yes"}
          buttonType={"contained"}
          onClick={deleteAddress}
        />
      </div>
    </div>
  );
};

export default DeleteAddress;
