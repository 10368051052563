import React, { useMemo } from "react";
import mapstyle from "../../data/googleMapStyle/google-map-style.json";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { useAppContext } from "../../global/gjcContext";

const CheckoutLocation = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBW7x19KZzppCb5jI22Vn9CULzNYi1YPgQ",
  });

  if (!isLoaded) return <div> loading...</div>;
  return (
    <tr>
      <td colSpan={2}>
        <div className="checkout-location">
          <Map />
        </div>
      </td>
    </tr>
  );
};

export default CheckoutLocation;

const Map = () => {
  const { singleOutlet, orderType } = useAppContext();

  const center = useMemo(
    () => ({
      lat: orderType === "pickup" && singleOutlet.geolocation.coordinates[0],
      lng: orderType === "pickup" && singleOutlet.geolocation.coordinates[1],
    }),
    [singleOutlet]
  );

  return (
    <GoogleMap
      options={{
        styles: mapstyle,
      }}
      zoom={15}
      center={center}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {/* here will be a check for all the place :: TODO : show the location whice is selected in pickup */}
      <Marker position={center} />
    </GoogleMap>
  );
};
