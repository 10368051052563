import React, { useState, useEffect } from "react";
import { useAppContext } from "../../global/gjcContext";
import CartActionButtons from "./cart-action-buttons";
import CartItem from "./cart-item";
import CartSubTotal from "./cart-subtotal";
import { subTotalCountIncludeAddons } from "../../utilities/cart/subtotal-count";
import {
  increaseVariation,
  decreaseVariation,
  handleChecked,
  handleUnChecked,
} from "../../utilities/cart/variation-item-count";
import {
  increaseAddons,
  decreaseAddons,
} from "../../utilities/cart/addons-item-count";
import CheckoutTableHeading from "../checkout/checkout-table-heading";

const CartTable = () => {
  const { cartItems, setCartItems, SubTotal, setSubTotal } = useAppContext();

  // calculating subtotal of all items in cart including addons
  const subTotalCountWithAddons = () => {
    let total = subTotalCountIncludeAddons(cartItems);

    setSubTotal(total);
  };
  //// incrementing  qty of items in cart variations
  const increaseVariationQty = (item_index, index) => {
    let newarr = increaseVariation(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// decrementing qty of items in cart variations
  const decreaseVariationQty = (item_index, index) => {
    let newarr = decreaseVariation(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// incrementing  qty of items in cart addons
  const increaseAddonsQty = (item_index, index) => {
    let newarr = increaseAddons(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// incrementing  qty of items in cart addons
  const decreaseAddonsQty = (item_index, index) => {
    let newarr = decreaseAddons(cartItems, item_index, index);
    setCartItems(newarr);
  };

  //// chnage checked on click of checkbox
  const handleOnclickChecked = (item_index) => {
    let newarr = handleChecked(cartItems, item_index);
    setCartItems(newarr);
  };
  const handleOnclickUnChecked = (item_index) => {
    let newarr = handleUnChecked(cartItems, item_index);
    setCartItems(newarr);
  };

  useEffect(() => {
    subTotalCountWithAddons();
  }, [cartItems]);
  return (
    <table
      className="cart-item-wrapper col-1"
      style={{ borderBottom: "0.5px solid rgba(171, 148, 109, 0.5)" }}
    >
      <tbody>
        <tr>
          <CheckoutTableHeading title={"Cart"} sub_title="Summary" />
        </tr>
        {cartItems.length > 0 ? (
          cartItems.map((items, item_index) => (
            <CartItem
              key={item_index}
              items={items}
              item_index={item_index}
              handleOnclickChecked={handleOnclickChecked}
              handleOnclickUnChecked={handleOnclickUnChecked}
              increaseVariationQty={increaseVariationQty}
              increaseAddonsQty={increaseAddonsQty}
              decreaseVariationQty={decreaseVariationQty}
              decreaseAddonsQty={decreaseAddonsQty}
            />
          ))
        ) : (
          <h2>Your Cart is empty</h2>
        )}
        <CartSubTotal SubTotal={SubTotal} />
        <CartActionButtons />
      </tbody>
    </table>
  );
};

export default CartTable;
