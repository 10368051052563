export const totalItemCountFunc = (checkoutCartItems) => {
  let total = 0;
  checkoutCartItems.map((item) => {
    item.variation.map((variation) => {
      total += variation.qty;
    });
  });
  return total;
  // console.log(total);
  // setTotalItem(total);
};
