import React from "react";
import "./Promotion.css";
import "../../App.css";

const PromotionCard = ({ promotion }) => {
  const { name, description, imgURL, expiryDate } = promotion;

  return (
    <div className="card">
      <img src={imgURL} className="card-img" />

      <div className="card-content">
        <h4>{name}</h4>
        <p>
          <hr className="solid" />
        </p>
        <p className="description">{description}</p>
        {expiryDate && (
          <p className="expire-date">
            Valid till:{" "}
            {new Date(expiryDate).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default PromotionCard;
