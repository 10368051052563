import React, { useState } from "react";
import SectionTitle from "../header/Section-title";
import TabNavbar from "../outlet-section/tab-navbar";
import SingleTabContent from "../outlet-section/SingleTabContent";
import { useAppContext } from "../../../global/gjcContext";

const Outlets = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { outlets } = useAppContext();
  const handleActive = (itemIndex) => {
    setActiveTab(itemIndex);
  };
  // console.log("outlets json data:", outletsdata);
  return (
    <section id="find-outlet" className="boxed-container outlets-section">
      <SectionTitle
        imgUrl={"images/home/outlets.png"}
        startText="Enjoy our"
        highlightedtext={"Ambience"}
      />
      <div>
        {outlets && (
          <TabNavbar
            activeTab={activeTab}
            handleActive={handleActive}
            outlets={outlets}
          />
        )}

        <SingleTabContent
          outlet={
            outlets &&
            outlets.filter((item, index) => activeTab === index).length > 0
              ? outlets.filter((item, index) => activeTab === index)[0]
              : null
          }
        />
      </div>
    </section>
  );
};

export default Outlets;
