export const cartValue = // /// checking localStorage cart Items [ if null set [] or the value in localStorage 'cartItems']
  !localStorage["cartItems"]
    ? []
    : localStorage.getItem("cartItems") === undefined
    ? []
    : JSON.parse(localStorage.getItem("cartItems"));

export const checkOutValue = // /// checking localStorage cart Items [ if null set [] or the value in localStorage 'cartItems']
  !localStorage["checkoutCartItems"]
    ? []
    : localStorage.getItem("checkoutCartItems") === undefined
    ? []
    : JSON.parse(localStorage.getItem("checkoutCartItems"));

export const ipnvalue = !localStorage["ipnvalue"]
  ? ""
  : localStorage["ipnvalue"] === "undefined"
  ? ""
  : JSON.parse(localStorage.getItem("ipnvalue"));

export const paymentStatus = !localStorage["paymentStatus"]
  ? 0
  : localStorage.getItem("paymentStatus") === undefined
  ? 0
  : JSON.parse(localStorage.getItem("paymentStatus"));

export const deliveryAddressInfovalue = !localStorage["deliveryAddressInfo"]
  ? {
      house: "",
      road: "",
      sector: "",
      direction: "",
      area: "",
    }
  : localStorage["deliveryAddressInfo"] == "undefined"
  ? {
      house: "",
      road: "",
      sector: "",
      direction: "",
      area: "",
    }
  : JSON.parse(localStorage.getItem("deliveryAddressInfo"));

export const savethisAddress = !localStorage["savethisAddress"]
  ? false
  : localStorage.getItem("savethisAddress") === undefined
  ? false
  : JSON.parse(localStorage.getItem("savethisAddress"));
