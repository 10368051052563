import React from "react";
import FoodContent from "./food/FoodContent";
import MetaData from "./layouts/MetaData";

const FoodMenu = () => {
  return (
    <>
      <MetaData title={"Home"} />
      <div className="container">
        <div className="mymizformenu">
          {/* <FoodHeader /> */}
          <FoodContent />
          {/* <ForTest /> */}
        </div>
      </div>
    </>
  );
};

export default FoodMenu;
