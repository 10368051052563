import React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Svg } from "/images/not-found.svg";

const Redirect404 = () => {
  const navigate = useNavigate();
  return (
    <div className="container page">
      <div
        className="content grid-two"
        style={{ padding: "clamp(2rem, 8%, 8rem) 0", alignItems: "center" }}
      >
        {/* Left column with image */}
        <div>
          <img src="/images/not-found.svg" alt="not-found" />
        </div>

        {/* Right column with texts */}
        <div className="lost-right">
          <h1 className="orange">404</h1>
          <h2>Page Not Found</h2>
          <p>
            We’re sorry, the page you requested could not be found. Please go
            back to the homepage!
          </p>
          <button
            className="primary"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Redirect404;
