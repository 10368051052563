import React from "react";
import CategoryCard from "../cards/category-card";
import SectionTitle from "../header/Section-title";

const ServiceSection = () => {
  return (
    <section id="service-section" className="pattern-bg">
      <div className="boxed-container">
        <SectionTitle
          imgUrl={"/images/home/services.png"}
          startText={"Enjoy your"}
          highlightedtext={"Convenience"}
        />
        <div className=" service-section">
          <div className="service-right">
            <CategoryCard
              detailsPosition={"right"}
              widthHight={"service-card-width-height"}
              shouldBtn={false}
              imgSrc={"Assets/img/home/Dine-in-min.jpg"}
              title={"Have a great experience while"}
              titleSpan={"dining in"}
              details={
                // "Dine-in and enjoy your meal with elegance, at any nearby outlet."
                "Savor a luxurious dining experience at one of our convenient nearby outlets."
              }
            />
          </div>
          <div className="service-left">
            <CategoryCard
              detailsPosition={"left"}
              widthHight={"service-card-width-height"}
              shouldBtn={false}
              imgSrc={"Assets/img/home/Takeaway-min.jpg"}
              title={"Enjoy the convenience of"}
              titleSpan={"takeway"}
              details={
                "Enjoy the convenience of taking our delicious coffee and food with you wherever you go, by ordering for takeaway."
              }
            />
          </div>
          <div className="service-right">
            <CategoryCard
              detailsPosition={"right"}
              widthHight={"service-card-width-height"}
              shouldBtn={false}
              imgSrc={"Assets/img/home/Pick-up-min.jpg"}
              title={"When in a hurry, order for"}
              titleSpan={"pick-up"}
              details={
                "Save time and grab your coffee or food, while you are on the go. Place your order for pick-up."
              }
            />
          </div>
          <div className="service-left">
            <CategoryCard
              detailsPosition={"left"}
              widthHight={"service-card-width-height"}
              shouldBtn={false}
              imgSrc={"Assets/img/home/Delivery-min.jpg"}
              title={"Enjoy your meal anywhere by ordering for"}
              titleSpan={"delivery"}
              details={
                "Get your delicious meal and cup of coffee delivered at your doorstep."
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
