export const subTotalCountIncludeAddons = (cartItems) => {
  let total = 0;
  cartItems.map((item) => {
    item.variation.map((variation) => {
      total += variation.price * variation.qty;
    });
    item.addOns.map((addOn) => {
      total += addOn.price * addOn.qty;
    });
  });

  return total;
  // setSubTotal(total);
};
