import React from "react";
import { ImageButton } from "../../buttons/Button";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import {
  animationContainer,
  fadeIn,
  fadeInLeft,
  fadeInRight,
} from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const CategoryCard = ({
  detailsPosition,
  widthHight,
  shouldBtn,
  heading,
  title,
  titleSpan,
  details,
  imgSrc,
  onClick,
  menu,
}) => {
  const navigate = useNavigate();
  const servicesAnimation = useAnimateOnInView();
  return (
    <motion.div
      ref={servicesAnimation.ref}
      animate={servicesAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className={`category-card ${widthHight}`}
    >
      <motion.div
        variants={detailsPosition == "right" ? fadeInLeft : fadeInRight}
        className="category-card__image"
      >
        <img src={imgSrc} alt="category-food" />
      </motion.div>
      <div
        className={`category-card__details details-position-${detailsPosition}`}
      >
        <div className="contents">
          {heading}
          <motion.h3 variants={fadeIn}>
            {title}&nbsp;<span className="highlighted">{titleSpan}</span>
          </motion.h3>
          <motion.p variants={fadeIn}>{details}</motion.p>
        </div>
        {shouldBtn ? (
          <ImageButton
            classname={"arrow"}
            buttonImgSrc="/images/icons/arrow-right.png"
            alt={"arrow-right"}
            buttonType={"redirect-image"}
            onClick={() => navigate("/menu", { state: { menu: menu } })}
          />
        ) : (
          ""
        )}
      </div>
    </motion.div>
  );
};

export default CategoryCard;
