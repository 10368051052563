import React from "react";
import Button from "../buttons/Button";
import { useAppContext } from "../../global/gjcContext";
import { useNavigate } from "react-router-dom";

const CartActionButtons = () => {
  const { cartItems, setcheckoutCartItems } = useAppContext();
  const navigate = useNavigate();

  const FilterCartItemforCheckout = () => {
    /// it will be called in checkout button onChick
    let cartdata = [...cartItems];
    const Checkoutdata =
      cartdata.length > 0
        ? cartdata.map((item) => {
            let length = item.variation.filter(
              (variation) => variation.qty > 0
            ).length;
            return length > 0 ? item : null;
          })
        : [];
    let filterCheckoutdata = Checkoutdata.filter((item) => item !== null);

    if (filterCheckoutdata.length > 0) {
      setcheckoutCartItems(filterCheckoutdata);
      navigate("/checkout");
    } else {
      setcheckoutCartItems(filterCheckoutdata);
      alert("Please add some items for checkout");
    }
  };

  return (
    <tr>
      <td colSpan={3}>
        <div className="cart-action-buttons">
          <Button
            onClick={() => {
              navigate("/menu");
            }}
            buttonText={"Continue Shopping"}
            buttonType="outlined"
          />
          <Button
            onClick={FilterCartItemforCheckout}
            buttonText={"Checkout"}
            buttonType="contained"
          />
        </div>
      </td>
    </tr>
  );
};

export default CartActionButtons;
