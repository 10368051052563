import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import PopularItemCard from "../cards/popular-item-card";
import api from "../../../api/axios";
import { useAppContext } from "../../../global/gjcContext";
import Loader from "../../layouts/Loader";

const PopulerItemsCarousel = () => {
  const [populerItems, setPopulerItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { singleOutlet, orderType } = useAppContext();

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    responsive: [
      {
        breakpoint: 2700,
        settings: {
          slidesToShow: populerItems.length < 6 ? populerItems.length : 6,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: populerItems.length < 4.6 ? populerItems.length : 4.6,
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: populerItems.length < 4 ? populerItems.length : 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: populerItems.length < 3.5 ? populerItems.length : 3.5,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: populerItems.length < 2.6 ? populerItems.length : 2.6,
        },
      },

      {
        breakpoint: 820,
        settings: {
          slidesToShow: populerItems.length < 2 ? populerItems.length : 2,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getPopulerItems = async (outletID) => {
    try {
      const response = await api.get(`/item/featured/${outletID}`);
      // console.log("Getting populer Items:", response.data);
      setPopulerItems(response.data);
    } catch (err) {
      console.log("Axios Error: ", err);
    }
  };

  useEffect(() => {
    // console.log(":::::SINGLE OUTLET:::::: ", singleOutlet);

    if (singleOutlet && singleOutlet != null && singleOutlet != undefined) {
      let outletID;

      if (orderType === "delivery") {
        outletID = singleOutlet.outlet ? singleOutlet.outlet._id : null;
      } else {
        outletID = singleOutlet._id;
      }

      if (!outletID) {
      } else {
        getPopulerItems(outletID);
      }
    }
  }, [singleOutlet]);

  useEffect(() => {
    populerItems.length > 0 && setDataLoaded(true);
  }, [populerItems]);

  return (
    <section className="popular-item-section">
      <div className="container">
        <div className="content popular-title-wrapper">
          <h2 className="populer-item-section-title">
            Populer <span>Item</span>
          </h2>
        </div>
      </div>

      <div className="popular-item-carousel-box">
        {!dataLoaded ? (
          <Loader />
        ) : populerItems.length > 0 ? (
          <Slider {...settings} className="popular-item-slider">
            {populerItems.map((item, index) => (
              <PopularItemCard item={item} key={index} />
            ))}
          </Slider>
        ) : (
          <h3
            style={{
              textAlign: "center",
              border: "1px solid rgba(255, 127, 80, 0.5)",
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            No Popular Items available at the moment. Sorry for the
            inconvenience.
          </h3>
        )}
      </div>
    </section>
  );
};

export default PopulerItemsCarousel;
