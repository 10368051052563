//// incrementing  qty of items in cart variations
/// --------------------------------------------------------------------
export const increaseVariation = (cartItems, item_index, variation_index) => {
  let newarr = [...cartItems];
  // console.log(newarr[i]);
  newarr[item_index].variation[variation_index].qty += 1;

  return newarr;
};

//// decrementing qty of items in cart variations
/// --------------------------------------------------------------------
export const decreaseVariation = (cartItems, item_index, variation_index) => {
  let newarr = [...cartItems];
  // console.log(newarr[i]);
  if (newarr[item_index].variation[variation_index].qty > 0) {
    newarr[item_index].variation[variation_index].qty -= 1;
  }
  if (
    newarr[item_index].variation.filter((variation) => variation.qty !== 0)
      .length === 0
  ) {
    newarr[item_index].addOns.map((addOn) => {
      addOn.qty = 0;
    });
  }
  return newarr;
};

//// chnage checked on click of checkbox
export const handleChecked = (cartItems, item_index) => {
  let newarr = [...cartItems];
  newarr[item_index].variation.map((variation) => {
    if (variation.qty > 0) {
      variation.qty = 0;
      newarr[item_index].addOns.map((addOn) => {
        addOn.qty = 0;
      });
    }
    //  else {
    //   variation.qty = 1;
    //   // newarr[item_index].addOns.map((addOn) => {
    //   //   addOn.qty = 0;
    //   // });
    // }
  });
  return newarr;
};

export const handleUnChecked = (cartItems, item_index) => {
  let newarr = [...cartItems];
  newarr[item_index].variation.map((variation) => {
    if (variation.qty === 0) {
      variation.qty = 1;
      newarr[item_index].addOns.map((addOn) => {
        addOn.qty = 0;
      });
    }
    //  else {
    //   variation.qty = 1;
    //   // newarr[item_index].addOns.map((addOn) => {
    //   //   addOn.qty = 0;
    //   // });
    // }
  });
  return newarr;
};
