import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "../checkout/checkout.css";
import api from "../../api/axios";
import OrderDetailTable from "./orderDetailTable";

import Accepted from "./order-status/Accepted";
import Pending from "./order-status/Pending";
import Preparing from "./order-status/Preparing";
import Prepared from "./order-status/Prepared";
import Delivering from "./order-status/Delivering";
import Completed from "./order-status/Completed";
import Canceled from "./order-status/Canceled";
import Loader from "../layouts/Loader";

const OrderDetail = () => {
  const location = useLocation();
  const { orderID } = useParams();
  const [orderData, setOrderData] = useState({});

  const getSingleOrderDetail = async () => {
    try {
      const response = await api.get(`/user/order/${orderID}`);
      // console.log("Response Data:", response.data);
      setOrderData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleOrderDetail();
  }, []);

  // console.log(location);
  // console.log("orderID:", orderID);
  return (
    <>
      {orderData === null ? (
        <div
          style={{
            marginTop: "10rem",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>No such order 🤔 </h1>
        </div>
      ) : (
        <div className="checkout container">
          <div className="checkout-grid-2 content">
            <div className="col-1">
              <div className="checkout-details-wrapper">
                <OrderDetailTable data={orderData} />
              </div>
            </div>
            <div className="col-2">
              <div className="checkout-image-wrapper">
                {orderData.orderStatus === "Pending" ? (
                  <Pending />
                ) : orderData.orderStatus === "Accepted" ? (
                  <Accepted />
                ) : orderData.orderStatus === "Preparing" ? (
                  <Preparing />
                ) : orderData.orderStatus === "Prepared" ? (
                  <Prepared />
                ) : orderData.orderStatus === "Delivering" ? (
                  <Delivering />
                ) : orderData.orderStatus === "Completed" ? (
                  <Completed />
                ) : orderData.orderStatus === "Completed" ? (
                  <Canceled />
                ) : (
                  <Loader />
                )}

                {/* <img
                  style={{ maxWidth: "80%" }}
                  src={`/images/${
                    orderData.orderStatus === "Pending"
                      ? "pending"
                      : orderData.orderStatus === "Preparing"
                      ? "preparing"
                      : orderData.orderStatus === "Prepared"
                      ? "prepared"
                      : orderData.orderStatus === "Delivering"
                      ? "delivering"
                      : orderData.orderStatus === "Completed"
                      ? "completed"
                      : "cancelled"
                  }.svg`}
                  alt="checkout-image"
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetail;
