import React, { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import api from "../../api/axios";
import { OurGjcContext, useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";

const Register = () => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const [navigatePath, setNavigatePath] = useState("/login");

  const navigate = useNavigate();
  const prevLocation = useLocation();

  useEffect(() => {
    if (!prevLocation.state) {
      //
    } else {
      setNavigatePath(prevLocation.state.prevLocation);
    }
  }, [prevLocation]);

  const { setisLoggedIn, setCurrentUser } = useContext(OurGjcContext);

  const userRegister = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await api.post("/register", {
        name: name,
        phone: phone,
        email: email,
        password: pwd,
      });
      if (response.data.ok === true) {
        setSuccess(true);
        setError(false);

        setCurrentUser(response.data.user);
        setisLoggedIn(true);

        localStorage.setItem("isLoggedIn", true);

        localStorage.setItem("userData", JSON.stringify(response.data.user));

        // Token works
        if (response.data) {
          localStorage.setItem("token", response.data.token);
          navigate(navigatePath, {});
        } else {
          alert(response.data);
        }
        // Token works
      } else {
        setError(response.data);
        setSuccess(false);
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className="auth">
      <div className="content">
        <div className="auth-form">
          <h1>Register</h1>
          <p>Fill out the form to create an account.</p>
          <form className="primary" onSubmit={userRegister}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
            ></input>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              onChange={(e) => setPhone(e.target.value)}
            ></input>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email (optional)"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              type="password"
              id="pwd"
              name="pwd"
              placeholder="Password"
              onChange={(e) => setPwd(e.target.value)}
            ></input>
            <button type="submit" className="primary">
              Register
            </button>
          </form>
          <p className="alt-prompt">
            Already have an account?{" "}
            <Link to="/login">
              <span>
                <b>Log In</b>
              </span>
            </Link>
          </p>
          {error && <p className="error">{error}</p>}
          {success && (
            <p className="error">
              You are successfuly registered!
              {/* <Navigate to={navigatePath} replace={true} /> */}
            </p>
          )}
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Register;
