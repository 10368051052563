import React from "react";
import PromotionCard from "./PromotionCard";
import "../../App.css";
import { useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const Promotions = () => {
  const { promotions } = useAppContext();
  console.log(promotions);
  return (
    <div className="container">
      <MetaData title={"Promotions"} />
      <div className="promotions content">
        <h1 className="title">Promotions</h1>
        {!promotions ? (
          <Loader />
        ) : promotions.length > 0 ? (
          <div className="promotion-container">
            {promotions.map((promotion) => (
              <PromotionCard
                key={promotion._id}
                promotion={promotion}
              ></PromotionCard>
            ))}
          </div>
        ) : (
          <div>
            <h3>
              There are no ongoing promotions at the moment, sorry for the
              inconvenience.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Promotions;
