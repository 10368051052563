import React from "react";

const CheckOutItem = ({ item, variation, variation_index }) => {
  return (
    <tr key={variation_index}>
      <td>
        <span className="bulletpoint"></span>
        <span className="checkout-item-label"> {item.name}</span>
      </td>
      <td>
        <span className="checkout-item-label">{variation.qty}</span>
      </td>
    </tr>
  );
};

export default CheckOutItem;
