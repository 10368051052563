import React, { useEffect } from "react";
import CheckoutTable from "./checkout-table";
import "./checkout.css";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../global/gjcContext";

const Checkout = () => {
  const navigate = useNavigate();
  const { checkoutCartItems } = useAppContext();
  useEffect(() => {
    if (checkoutCartItems.length === 0) {
      alert("Please check your cart items");
      navigate("/cart");
    }
  }, [checkoutCartItems]);

  return (
    <div className="checkout container">
      <div className="checkout-grid-2 content">
        <div className="col-1">
          <div className="checkout-details-wrapper">
            <CheckoutTable />
          </div>
        </div>
        <div className="col-2">
          <div className="checkout-image-wrapper">
            <img src="/images/checkout.svg" alt="checkout-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
