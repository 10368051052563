import React, { Fragment } from "react";
import OrderAddons from "./order-addons";
import OrderVariation from "./order-variation";

const OrderItem = ({ item, price, index }) => {
  return (
    <Fragment key={index}>
      <tr>
        <td>
          <span className="orderdetails-item-label"> {item.name}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span className="orderdetails-item-sub-label"> Size</span>
        </td>
      </tr>
      {item.variation !== undefined && item.variation.length > 0
        ? item.variation.map((variation, variation_index) => (
            <OrderVariation
              variation={variation}
              variation_index={variation_index}
            />
          ))
        : ""}
      {item.addOns !== undefined &&
      item.addOns.filter((addon) => addon.qty > 0).length > 0 ? (
        <tr key={index}>
          <td>
            <span className="orderdetails-item-sub-label"> Add-Ons</span>
          </td>
        </tr>
      ) : (
        ""
      )}
      {item.addOns !== undefined && item.addOns.length > 0
        ? item.addOns.map(
            (addon, index) =>
              addon.qty > 0 && <OrderAddons index={index} addon={addon} />
          )
        : ""}
    </Fragment>
  );
};

export default OrderItem;
