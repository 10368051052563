import React from "react";
import ItemCard from "./ItemCard";

const CategorySection = ({ categories, setModalOpen, setSelectedItem }) => {
  const renderSection = (category, index) => {
    // console.log("Category:::", category);

    return (
      category.childs &&
      category.childs.length > 0 && (
        <section className="category-section">
          <h2 id={category.name} className="cat-name">
            {category.name}{" "}
            {category.remark ? (
              <>
                <br />
                <span>({category.remark})</span>
              </>
            ) : (
              ""
            )}
          </h2>
          <div className="item-grid">
            {category.childs.map((item, i) => (
              <ItemCard
                item={item}
                setModalOpen={setModalOpen}
                setSelectedItem={setSelectedItem}
                key={i + item._id}
              />
            ))}
          </div>
        </section>
      )
    );
  };

  return (
    <>
      {!categories || categories.length < 0 ? (
        <>
          <section className="category-section">
            <h2 className="cat-name">No items added to this menu..</h2>
          </section>
          <section
            style={{ height: "12rem" }}
            className="category-section"
          ></section>
        </>
      ) : (
        categories.length > 0 && (
          <>
            <div className="categories-wrapper">
              {categories.map(renderSection)}
            </div>
            <section
              style={{ height: "12rem" }}
              className="category-section"
            ></section>
          </>
        )
      )}
    </>
  );
};

export default CategorySection;
