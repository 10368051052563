import React, { useState, useEffect, useRef, useContext } from "react";
import "./EcommerceHeader.css";
import { OurGjcContext } from "../../global/gjcContext";
import Modal from "../layouts/Modal";
import OrderType from "./OrderType";
import Search from "../menu/Search";
import WarningOutletChange from "./WarningOutletChange";

const EcommerceHeader = (countries) => {
  // taking essential context elements from OurGjcContext
  const {
    outlets,
    selectedOutlet,
    outletChangeHandler,
    orderType,
    cartItems,
    deliveryLocations,
    selectedDeliveryLocation,
    deliveryLocationChangeHandler,
    setCartItems,
    setcheckoutCartItems,
  } = useContext(OurGjcContext);

  const [countriesExpand, setCountriesExpand] = useState(false);

  /*************************** Delivery & Pickup dropdown starts ***********************/

  const [modalOpen, setModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [outletIndex, setoutletIndex] = useState(0);
  /*************************** Delivery & Pickup dropdown starts ***********************/
  const handleOrderType = (outletIndex) => {
    orderType === "delivery"
      ? deliveryLocationChangeHandler(outletIndex)
      : outletChangeHandler(outletIndex);
    setWarningModalOpen(false);
    setCartItems([]);
    setcheckoutCartItems([]);
  };

  let countryRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        countriesExpand && setCountriesExpand(false);
      }
    });
  });

  return (
    <>
      <div className="eComNavbar justified-between content">
        <div className="leftPart">
          <button
            className="tertiary highlighted"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {orderType}
          </button>
          {/* Order type Modal */}
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <OrderType onClose={() => setModalOpen(false)} />
          </Modal>
          <Modal
            open={warningModalOpen}
            showButton="true"
            onClose={() => {
              setWarningModalOpen(false);
            }}
          >
            <WarningOutletChange
              handleOrderType={() => {
                handleOrderType(outletIndex);
              }}
              onClose={() => {
                setWarningModalOpen(false);
              }}
            />
          </Modal>

          {/* calling change handler function for onchange */}
          {/* To show selected outlet name in select default value from localstorage "value={selectedOutlet}" is used */}

          {orderType == "delivery" ? (
            <select
              onChange={(e) => {
                setoutletIndex(e.target.value);
                if (orderType === "delivery" && cartItems.length > 0) {
                  setWarningModalOpen(true);
                } else {
                  deliveryLocationChangeHandler(e.target.value);
                }
              }}
              value={selectedDeliveryLocation}
            >
              {deliveryLocations &&
                deliveryLocations.map((deliveryLocation, i) => (
                  <>
                    <option value={i}>{deliveryLocation.name}</option>
                  </>
                ))}
            </select>
          ) : (
            <select
              onChange={(e) => {
                setoutletIndex(e.target.value);
                if (orderType === "pickup" && cartItems.length > 0) {
                  setWarningModalOpen(true);
                } else {
                  outletChangeHandler(e.target.value);
                }
              }}
              value={selectedOutlet}
            >
              {outlets &&
                outlets.map((outlet, i) => (
                  <>
                    <option value={i}>{outlet.name}</option>
                  </>
                ))}
            </select>
          )}
        </div>

        <div className="rightPart" ref={countryRef}>
          {/* <input type="text" placeholder="Search food/drinks" /> */}
          <Search />
          {/* Flags */}

          <div className="countries">
            <button
              className="countriesButton"
              onClick={() => {
                setCountriesExpand((expand) => !expand);
              }}
            >
              <img src="Assets/img/countries/Bangladesh.png" alt="BD" />
              BD
            </button>
            <div
              className={
                countriesExpand
                  ? "countriesContainer container expand"
                  : "countriesContainer container"
              }
            >
              <div className="countryFlags">
                {countries.countries.map((flags) => (
                  <div>
                    <img src={flags.img} alt={flags.name} />
                    <a href={flags.website} title="">
                      {flags.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Flags */}
        </div>
      </div>
    </>
  );
};

export default EcommerceHeader;
