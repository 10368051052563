export const getTimeandDatewithMonthName = (updatedAt) => {
  const date = updatedAt;
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const time = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateWithMonthName = `${day} ${month}, ${year} ; ${time} `;
  return dateWithMonthName;
};
export const getSeparateTimeandDate = (updatedAt) => {
  const date = updatedAt;
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const time = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { day, month, year, time };
};
