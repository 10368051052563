import React, { useState, useEffect } from "react";
import CheckoutTableHeading from "../checkout/checkout-table-heading";
import { useAppContext } from "../../global/gjcContext";
import Devider from "../checkout/table-divider";
import CheckOutTotals from "../checkout/checkout-totals";
import OrderItem from "./orderItem";
import OrderInfoCard from "./orderInfoCard";
import Button from "../buttons/Button";
import { getTimeandDatewithMonthName } from "../../utilities/order-details/time-date";
import Modal from "../layouts/Modal";
import OrderCancel from "./order-cancel";

const OrderDetailTable = ({ data }) => {
  const { singleOutlet, orderType } = useAppContext();
  const [dateTime, setdateTime] = useState("");
  const [modal, setModal] = useState(false);

  const getTimeandDate = () => {
    const dateWithMonthName = getTimeandDatewithMonthName(
      data.updatedAt !== undefined ? data.updatedAt : new Date()
    );
    setdateTime(dateWithMonthName);
    // console.log("dateWithMonthName", dateWithMonthName);
  };
  useEffect(() => {
    getTimeandDate();
    orderType === "pickup" && console.log(singleOutlet.geolocation.coordinates);
    // console.log("data:", data);
    // console.log("data outlet address:", data.outlet && data.outlet.address);
  }, [singleOutlet, orderType, data]);

  // make a function for getting direction function from google map

  const getDirection = () => {
    const url = data.outlet && data.outlet.googleMapLink;
    window.open(url, "_blank");
  };
  return (
    <table style={{ borderSpacing: "0 12px" }}>
      {/* <Modal
        open={modal}
        showButton="true"
        onClose={() => {
          setModal(false);
        }}
      >
        <OrderCancel
          onClose={() => {
            setModal(false);
          }}
          orderID={data._id}
          orderStatus={data.orderStatus}
        />
      </Modal> */}
      {data !== null ? (
        <tbody>
          <tr>
            <CheckoutTableHeading title={"Order Detail"} />
            {data.orderStatus === "Pending" ? (
              <td>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {/* <Button
                    onClick={() => {
                      setModal(true);
                    }}
                    buttonText={"Cancel order"}
                    buttonType="cancel"
                  /> */}
                </div>
              </td>
            ) : (
              <td>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <a href="#" onClick={getDirection}>
                    <span
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: "2px",
                        fontSize: "clamp( 0.8rem, 2vw, 1rem )",
                      }}
                    >
                      Get Direction
                    </span>
                  </a>
                </div>
              </td>
            )}
          </tr>
          <OrderInfoCard
            orderID={data._id}
            orderStatus={data.orderStatus}
            dateTime={dateTime}
            orderType={data.orderType}
            outletAddress={data.outlet && data.outlet.address}
            deliveryAddress={data.deliveryAddress && data.deliveryAddress}
          />
          <CheckoutTableHeading sub_title={"Summary"} />
          <Devider />
          {data.items !== undefined && data.items.length > 0 ? (
            data.items.map((item, item_index) => (
              <>
                <OrderItem index={item_index} item={item} price={450} />
                <Devider />
              </>
            ))
          ) : (
            <tr>
              <td colSpan={2}>
                <h3>No items</h3>
              </td>
            </tr>
          )}

          <CheckOutTotals SubTotal={data.total} />
        </tbody>
      ) : (
        ""
      )}
    </table>
  );
};

export default OrderDetailTable;
