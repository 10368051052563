import React, { useState } from "react";
import Modal from "./layouts/Modal";
import { Link } from "react-router-dom";

const Launch = () => {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <div
      id="lauch"
      style={{
        backgroundImage: `url("/Assets/img/Slide1-desktop-min.jpg")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(true)}
        showButton={false}
      >
        <div
          style={{
            width: "50vw",
            height: "50vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "2.6rem",
              fontWeight: "normal",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            <span
              style={{
                fontSize: "4rem",
                // fontWeight: "bold",
                fontStyle: "normal",
              }}
            >
              Gloria Jean's Coffees
            </span>
            <br />
            Website Soft Launch
          </h1>
          <button className="primary">
            <Link to="/" style={{ fontSize: "2rem" }}>
              Lauch
            </Link>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Launch;
