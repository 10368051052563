import React from "react";
// import Button from "../../buttons/Button";
import { Link } from "react-router-dom";
import OfferCard from "../cards/offer-card";

import { motion } from "framer-motion";
import {
  animationContainer,
  sentence,
  letter,
  // smokeIn,
} from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";
import { useAppContext } from "../../../global/gjcContext";
import Loader from "../../layouts/Loader";
import { useEffect } from "react";
import { useState } from "react";

const Hero = () => {
  const heroAnimation = useAnimateOnInView();
  const offerAnimation = useAnimateOnInView();

  const { promotions } = useAppContext();

  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 1,
      },
    },
  };

  const OfferContainer = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 1.5,
        easing: "easeIn",
      },
    },
  };

  const fadeInUpSlow = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const line1 = "Discover the ";
  const line2 = "finest coffee ";
  const line3 = "and food";
  return (
    <section id="hero-section" className="container">
      <div className="content hero-grid">
        <motion.div
          ref={heroAnimation.ref}
          animate={heroAnimation.animation}
          initial="hidden"
          variants={animationContainer}
          className="hero-content"
        >
          <motion.h1
            variants={sentence}
            initial="hidden"
            animate="visible"
            className="hero-title"
          >
            {line1.split("").map((char, index) => {
              return (
                <motion.span key={char + "-" + index} variants={letter}>
                  {char}
                </motion.span>
              );
            })}
            <br />
            <span className="highlighted">
              {line2.split("").map((char, index) => {
                return (
                  <motion.span key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                );
              })}
            </span>
            <br />
            {line3.split("").map((char, index) => {
              return (
                <motion.span key={char + "-" + index} variants={letter}>
                  {char}
                </motion.span>
              );
            })}
            {/* A lot can happen over <span>coffee</span> */}
          </motion.h1>
          <motion.p variants={fadeInUp} className="hero-subtitle">
            Indulge in the best coffee in town and enjoy an elegant dining
            experience at Gloria Jean's Coffees.
          </motion.p>
          {/* <Link to="/menu">
            <motion.button variants={fadeInUp} className="primary">
              Order Now
            </motion.button>
          </Link> */}
        </motion.div>
        {/* <motion.div
          ref={offerAnimation.ref}
          animate={offerAnimation.animation}
          initial="hidden"
          variants={OfferContainer}
          className="hero-offer">
          {

            promotions && promotions.length > 0 && promotions.map((promotion, i) => {
              var position = "top-right";
              if (i % 2 == 0) {
                position = "top-right";
              } else {
                position = "bottom-left margin-bottom";
              }

              var animDuration = 3 * 4;
              var animDelay = 3 * i;
              console.log("animDuration: ", animDuration);
              console.log("animDelay: ", animDelay);

              return (
                <div
                  // style={"--duration:" + animDuration + "; --delay:" + animDelay + ";"}
                  className={"offer-card " + position}
                >
                  <OfferCard
                    imgSrc={promotion.imgURL}
                    position={position}
                  >
                    {promotion.name}
                  </OfferCard>
                </div>)

            })
          }
        </motion.div> */}
        <motion.div
          ref={offerAnimation.ref}
          animate={offerAnimation.animation}
          initial="hidden"
          variants={OfferContainer}
          className="hero-offer"
        >
          <motion.div
            variants={fadeInUpSlow}
            className={`offer-card top-right`}
          >
            <OfferCard
              imgSrc="/Assets/img/promotions/offer-sehri.jpg"
              position={"top-right"}
            >
              Buy 2 Get 1 FREE on all Sehri Platters
            </OfferCard>
          </motion.div>

          <motion.div
            variants={fadeInUpSlow}
            className={`offer-card bottom-left margin-bottom`}
          >
            <OfferCard
              imgSrc="/Assets/img/promotions/offer-iftaar.jpg"
              position={"bottom-left"}
              className="margin-bottom"
            >
              Save 200 BDT with Iftaar Double Platter
            </OfferCard>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
