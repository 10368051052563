import React, { useState, useEffect } from "react";
import CheckoutTableHeading from "./checkout-table-heading";
import { useAppContext } from "../../global/gjcContext";
import { subTotalCountIncludeAddons } from "../../utilities/cart/subtotal-count";
import Devider from "./table-divider";
import CheckOutItem from "./checkout-item";
import CheckOutTotals from "./checkout-totals";
import CheckoutHeadings from "./checkout-heading";
import CheckoutActionButtons from "./checkout-action-buttons";
import CheckoutLocation from "./checkout-location";
import { totalItemCountFunc } from "../../utilities/checkout/chekout-total-count";
import CheckoutTotalItem from "./checkout-totalItems";
import CheckoutDeliveryAddress from "./checkout-delivary-address";

const CheckoutTable = () => {
  const {
    checkoutCartItems,
    SubTotal,
    setSubTotal,
    singleOutlet,

    orderType,
  } = useAppContext();

  const [totalItem, setTotalItem] = useState(0);

  useEffect(() => {
    const totalItemCount = () => {
      let total = totalItemCountFunc(checkoutCartItems);
      setTotalItem(total);
    };
    const subtotalCount = () => {
      const total = subTotalCountIncludeAddons(checkoutCartItems);
      setSubTotal(total);
    };
    totalItemCount();
    subtotalCount();
  }, [checkoutCartItems]);

  return (
    <form noValidate>
      <table>
        <tbody>
          <tr>
            <CheckoutTableHeading title={"Checkout"} sub_title={"Summary"} />
          </tr>
          <tr>
            <td>
              <h3 className="checkout-item-heading">Items</h3>
            </td>
            <td>
              <h3 className="checkout-item-heading">Quantity</h3>
            </td>
          </tr>
          <Devider />
          {checkoutCartItems.length > 0 ? (
            checkoutCartItems.map((item, item_index) =>
              item.variation.map((variation, variation_index) => (
                <CheckOutItem
                  item={item}
                  variation={variation}
                  variation_index={variation_index}
                />
              ))
            )
          ) : (
            <tr>
              <td colSpan={2}>
                <h3>No items</h3>
              </td>
            </tr>
          )}
          <Devider />
          <CheckoutTotalItem totalitem={totalItem} />
          <CheckOutTotals totalItem={totalItem} SubTotal={SubTotal} />
          {orderType === "pickup" ? (
            <>
              <CheckoutHeadings
                title={"Pick-up Location"}
                itemName={singleOutlet.name}
              />
              <CheckoutLocation />
            </>
          ) : (
            <CheckoutDeliveryAddress />
          )}
          <Devider />

          <CheckoutActionButtons />
        </tbody>
      </table>
    </form>
  );
};

export default CheckoutTable;
