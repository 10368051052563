import React from "react";
import Scrollspy from "react-scrollspy";

const CategoryNav = ({ categories }) => {
  const categoryList = [];

  for (const i in categories) {
    categoryList.push(categories[i].name);
  }

  return (
    <nav className="float-nav content">
      <Scrollspy
        items={categoryList}
        currentClassName="is-current"
        className="category-menu"
      >
        {!categories ? (
          <></>
        ) : (
          categories.length > 0 &&
          categories.map(
            (category, index) =>
              category.childs &&
              category.childs.length > 0 && (
                <li key={index}>
                  <a href={"#" + category.name}>{category.name}</a>
                </li>
              )
          )
        )}
      </Scrollspy>
    </nav>
  );
};

export default CategoryNav;
