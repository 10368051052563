import React, { useContext, useEffect, useState } from "react";
import api from "../../api/axios";
import "./UserProfile.css";
import { OurGjcContext } from "../../global/gjcContext";
import RemoveAddress from "./RemoveAddress";
import Modal from "../layouts/Modal";

const EditProfileTwo = ({ changeEditMode, getUser }) => {
  const { currentUser } = useContext(OurGjcContext);
  const [newName, setNewName] = useState();
  const [newEmail, setNewEmail] = useState();
  const [newPhone, setNewPhone] = useState();
  const [updating, setUpdating] = useState(false);

  // Update function
  const handleUpdate = async (e) => {
    // console.log("working from profile new");
    const params = {
      name: newName,
      email: newEmail,
      phone: newPhone,
    };

    try {
      setUpdating(true);
      await api.put("/updateProfile", params);
      getUser();
      setUpdating(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <h2 className="modal-heading">Edit Personal Information</h2>
      <br />
      <form style={{ margin: "1rem 0" }} className="primary scrollable">
        <table>
          <td>
            <div className="form-section" style={{ margin: "1rem 0" }}>
              <label for="addressName" style={{ margin: "0.5rem 0" }}>
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={newName}
                placeholder={currentUser.name}
                onChange={(e) => setNewName(e.target.value)}
              ></input>
            </div>

            <div className="form-section" style={{ margin: "1rem 0" }}>
              <label for="addressName" style={{ margin: "0.5rem 0" }}>
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={newEmail}
                placeholder={currentUser.email}
                onChange={(e) => setNewEmail(e.target.value)}
              ></input>
            </div>
            {/* <div className="form-section" style={{ margin: "1rem 0" }}>
              <label for="addressName" style={{ margin: "0.5rem 0" }}>
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={newPhone}
                placeholder={currentUser.phone}
                onChange={(e) => setNewPhone(e.target.value)}
              ></input>
            </div> */}
          </td>
        </table>

        <div className="profileEditModeActionButtons">
          <button className="secondary" onClick={() => changeEditMode()}>
            Cancel
          </button>
          <button
            className="primary"
            onClick={(e) => {
              e.preventDefault();
              //check if any of the fields are not empty
              if (newName || newEmail || newPhone) {
                handleUpdate();
                changeEditMode();
              } else {
                alert("Please fill at least one field to update");
              }
            }}
          >
            {updating ? "Updating Info" : "Update"}
          </button>
        </div>
      </form>
    </>
  );
};

export default EditProfileTwo;
