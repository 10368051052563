import React, { useCallback, useEffect, useState } from "react";
import api from "../../api/axios";
import { useAppContext } from "../../global/gjcContext";

const AddAddress = ({ onClose, setModalOpen, getUser }) => {
  const { deliveryLocations } = useAppContext();

  const [addressArea, setAddressArea] = useState(deliveryLocations[0]._id);
  const [addressName, setAddressName] = useState("");
  const [addressHouse, setAddressHouse] = useState("");
  const [addressRoad, setAddressRoad] = useState("");
  const [addressSector, setAddressSector] = useState("");
  const [addressDirection, setAddressDirection] = useState("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const saveAddress = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/user/address", {
        savedAddress: {
          name: addressName,
          house: addressHouse,
          road: addressRoad,
          sector: addressSector,
          area: addressArea,
          direction: addressDirection,
        },
      });

      if (response.data.success === true) {
        setSuccess(true);
        setError(false);
        getUser();
        // console.log(addressName, "has been saved");
      } else {
        setError(response.data);
        setSuccess(false);
      }

      setModalOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <h2 className="modal-heading">Add new address</h2>
      <br />
      <form className="primary scrollable">
        <div className="flex-row">
          <label for="addressArea" className="color-primary">
            Area
          </label>
          <select
            id="addressArea"
            name="addressArea"
            onChange={(e) => {
              setAddressArea(e.target.value);
            }}
          >
            {deliveryLocations &&
              deliveryLocations.map((deliveryLocation) => (
                <>
                  <option value={deliveryLocation._id}>
                    {deliveryLocation.name}
                  </option>
                </>
              ))}
          </select>
        </div>

        <div className="form-section">
          <label for="addressName">Address Name</label>
          <input
            type="text"
            id="addressName"
            name="addressName"
            placeholder="e.g. 'Home','Office', 'Friend's Place', etc."
            onChange={(e) => setAddressName(e.target.value)}
          ></input>
        </div>

        <div className="form-section">
          <label for="addressHouse">House</label>
          <input
            type="text"
            id="addressHouse"
            name="addressHouse"
            placeholder="e.g. 'House 20','Ahsan Manzil', etc."
            onChange={(e) => setAddressHouse(e.target.value)}
          ></input>
        </div>

        <div className="form-section">
          <label for="addressRoad">Road</label>
          <input
            type="text"
            id="addressRoad"
            name="addressRoad"
            placeholder="e.g. 'Road 7', 'Lane 2', 'Wise Lane', 'North road', etc."
            onChange={(e) => setAddressRoad(e.target.value)}
          ></input>
        </div>

        <div className="form-section">
          <label for="addressSector">Sector/Block</label>
          <input
            type="text"
            id="addressSector"
            name="addressSector"
            placeholder="e.g. 'Sector 12','Block B', 'Avenue 2', etc."
            onChange={(e) => setAddressSector(e.target.value)}
          ></input>
        </div>

        <div className="form-section">
          <label for="addressDirection">Additional Direction</label>
          <input
            type="text"
            id="addressDirection"
            name="addressDirection"
            placeholder="e.g. 'Opposite Tejgaon Aaarong', 'Second floor, flat 2A', etc."
            onChange={(e) => setAddressDirection(e.target.value)}
          ></input>
        </div>
      </form>

      <div className="newAddressActionButtons">
        <button className="secondary" onClick={onClose}>
          Cancel
        </button>
        <button className="primary" onClick={saveAddress}>
          Add
        </button>
      </div>
    </>
  );
};

export default AddAddress;
