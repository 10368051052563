import React, { useContext, useEffect, useState } from 'react'
import api from '../../api/axios';
import { OurGjcContext } from '../../global/gjcContext';

const RemoveAddress = ({ changeEditMode, addressId, setModalOpen, getUser, addressIndex }) => {

    // taking current user data from context
    const { currentUser, setCurrentUser } = useContext(OurGjcContext);
    const [addressDeleting, setAddressDeleting] = useState(false);

    useEffect(() => {
    }, [addressDeleting]);

    // Function to delete clicked address
    const handleAddressDelete = async () => {
        try {
            
            setAddressDeleting(true); // setAddressDeleting(true) = Deletion process initiate / Delete mode activating
            const response = await api.delete(`/user/address/${addressId}`);
            setCurrentUser(response.data.user); // Seting current user data in currentUser state from response
            getUser(); // getUser function updates userdata with updated data
            setAddressDeleting(false); //Turning off the deleting mode
            setModalOpen(false); //Close modal after delete
        } catch (error) {
            alert(error);
        }
    };

    return (
        <>
            <h1>Remove this address?</h1>
            <tr>
                <label>• {currentUser.savedAddress[addressIndex].name}</label>
                <p>
                    {currentUser.savedAddress[addressIndex].address}
                </p>
            </tr>
            <button
                className='secondary'
                onClick={
                    () => changeEditMode() /*Close modal and set edit mode false*/
                    }>No</button>
            <button
                className='secondary'
                onClick={
                    () => handleAddressDelete() //Calling delete function
                    }>{
                    addressDeleting ? "Deleting.." : "Yes" //Button text change based on state
                    }</button>
        </>
    )
}

export default RemoveAddress