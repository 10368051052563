import React from "react";
import { motion } from "framer-motion";
import { fadeInUp } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const OfferCard = ({ children, position, className, imgSrc, promotion }) => {

  return (
    // <motion.div initial={fadeInUp.hidden} animate={fadeInUp.visible} className={`offer-card ${position} ${className}`}>
    <>
      <div className="img-wrapper">
        <img className="offer-image" src={imgSrc} alt={children} />
      </div>
      <p className="content-wrapper">{children}</p>
    </>
    // </motion.div>
  );
};

export default OfferCard;
