import React from "react";

const CheckoutTableHeading = ({ title, sub_title }) => {
  return (
    <td>
      {title && (
        <h2
        // style={{

        //   color: "rgba(220,215,207,1)",
        //   fontWeight: "500",

        //   margin: "auto 0",
        // }}
        >
          {title}
        </h2>
      )}
      {sub_title && <h3 className="text-light subheading">{sub_title}</h3>}
    </td>
  );
};

export default CheckoutTableHeading;
