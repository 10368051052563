import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../global/gjcContext";
import Button from "../buttons/Button";
import "./payment.css";

const Payment = () => {
  const { setIpn, ipn } = useAppContext();
  const location = useLocation();
  const [url, setUrl] = useState("");
  // console.log("location", location);
  const urlAddress =
    location.state !== undefined ? location.state.data.GatewayPageURL : "#";
  const sessionKey =
    location.state !== undefined ? location.state.data.sessionkey : "";

  useEffect(() => {
    setIpn(sessionKey);
    if (urlAddress !== undefined) {
      setUrl(urlAddress);
    } else {
      alert("Check your internet connection");
      setUrl("checkout");
    }

    // console.log("response data ::", location.state.data.GatewayPageURL);
  }, [sessionKey, urlAddress]);

  const navigate = useNavigate();

  return (
    <div className="payment container">
      {location.state !== undefined ? (
        window.location.replace(url)
      ) : (
        <>
          <h1>Order Failed</h1>
          <p>Please checkout your order first</p>
          <Button
            onClick={() => {
              navigate("/checkout");
            }}
            buttonText={"Try Again"}
            buttonType="contained"
          />
        </>
      )}
    </div>
  );
};

export default Payment;
