import React from "react";
import "./buttons.css";
const Button = ({ buttonText, buttonType, onClick, submitType, width }) => {
  return (
    <button
      type={submitType}
      onClick={onClick}
      className={`button button-${buttonType}`}
      style={{ cursor: "pointer", width: width }}
    >
      {buttonText}
    </button>
  );
};
const OrderButton = ({
  buttonText,
  buttonType,
  onClick,
  submitType,
  width,
}) => {
  return (
    <button
      type={submitType}
      onClick={onClick}
      className={`button button-${buttonType}`}
      style={{ cursor: "pointer", width: width }}
    >
      {buttonText}
    </button>
  );
};

export default Button;

export const ImageButton = ({
  buttonImgSrc,
  alt,
  classname,
  buttonType,
  onClick,
}) => {
  return (
    <button
      // type={submitType}
      onClick={onClick}
      className={`button button-${buttonType} ${classname}`}
    >
      <img src={buttonImgSrc} alt={alt} />
    </button>
  );
};
