import React from "react";

import { motion } from "framer-motion";
import { animationContainer, zoomIn } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const VideoSection = ({ embedId }) => {
  const videoAnimation = useAnimateOnInView();
  return (
    <motion.section
      ref={videoAnimation.ref}
      animate={videoAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className="video-section container"
    >
      {/* <iframe
        width="1066"
        height="600"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1`}
        frameBorder="0"
        allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video Section"
      /> */}
      <motion.video
        variants={zoomIn}
        className="boxed-content"
        controls
        width="100%"
        autoPlay={"autoplay"}
        muted
        loop
      >
        <source src="Assets/video/sustainability-gjc-1.m4v" type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </motion.video>
    </motion.section>
  );
};

export default VideoSection;
