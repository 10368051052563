import React from "react";

const CartAddonsItem = ({
  addon,
  addon_index,
  increaseAddonsQty,
  decreaseAddonsQty,
  item_index,
  items,
}) => {
  return (
    <tr>
      <td
        className={` ${
          items.variation.filter((variation) => variation.qty !== 0).length ===
            0 || addon.qty === 0
            ? "disabled"
            : ""
        }`}
      >
        <div className="cart-sub-item-label">
          <span className="bulletpoint"></span>
          {addon.name}
        </div>
      </td>
      <td>
        <div className="middle">
          <button
            className="minus"
            onClick={() => {
              if (addon.qty > 0) {
                decreaseAddonsQty(item_index, addon_index);
              }
            }}
            disabled={
              items.variation.filter((variation) => variation.qty !== 0)
                .length !== 0
                ? false
                : true
            }
          >
            -
          </button>
          <input
            type="text"
            className="quantity"
            value={addon.qty}
            onChange={() => {}}
          ></input>
          <button
            className="plus"
            onClick={() => {
              increaseAddonsQty(item_index, addon_index);
            }}
            disabled={
              items.variation.filter((variation) => variation.qty !== 0)
                .length > 0
                ? false
                : true
            }
          >
            +
          </button>
        </div>
      </td>
      <td>
        {" "}
        <div className="cart-price-label">TK.{addon.price * addon.qty}</div>
      </td>
    </tr>
  );
};

export default CartAddonsItem;
