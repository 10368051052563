import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";

/**
 * This is a custom hook to toggle a Framer Motion animation
 * when an element is in view or out of view
 * @param {number} threshold - the threshold for when IN view
 * @returns {object} an object with the ref and the animation
 */

const useAnimateOnInView = () => {
  const animation = useAnimation();

  const [ref, inView] = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
    // else if (!inView){
    //   controls.start("hidden");
    // }
  }, [animation, inView]);

  return { ref, animation };
};

export default useAnimateOnInView;
