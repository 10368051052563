import React from "react";
import { motion } from "framer-motion";
import { animationContainer, fadeInUp } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const SectionTitle = ({ imgUrl, startText, highlightedtext }) => {
  const titleAnimation = useAnimateOnInView();
  return (
    <motion.div
      ref={titleAnimation.ref}
      animate={titleAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className="services section-title">
      {/* <motion.img
        variants={fadeInUp}
        className="subheading"
        src={imgUrl}
        loading="lazy"
      /> */}
      <motion.h2 variants={fadeInUp} className="text-center">
        {startText} <span>{highlightedtext}</span>
      </motion.h2>
    </motion.div>
  );
};

export default SectionTitle;
