import React, { useEffect, useState, useRef } from "react";
import "./menu.css";
import CategoryNav from "./CategoryNav";
import CategorySection from "./CategorySection";
import Loader from "../layouts/Loader";
import Modal from "../layouts/Modal";
import ItemDetails from "./ItemDetails";
import AddOnsDetails from "./AddOnsDetails";
import { useAppContext } from "../../global/gjcContext";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import api from "../../api/axios";

import MetaData from "../layouts/MetaData";

const GJCMenu = () => {
  const [menuType, setMenuType] = useState("Beverages");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filterselectedItem, setFilterSelectedItem] = useState({});
  const [isVariationSelected, setIsVariationSelected] = useState(false);

  const { setCartItems, outletID } = useAppContext();

  // Declaring state for Menu Data
  const [menuData, setMenuData] = useState();

  //Function to GET Menu Data and Cache in Local Storage
  const getMenuData = async (outletID) => {
    try {
      const response = await api.get("/menu/" + outletID);

      if (response.data) {
        response.data.Food.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newFood,
        });

        response.data.Beverages.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newBeverages,
        });

        response.data.Desserts.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newDesserts,
        });

        setMenuData(response.data);
      }
    } catch (error) {
      console.log("Axios Error: ", error);
    }
  };

  const didMount = React.useRef(false);

  //Fetching Menu Data from API
  // useEffect(() => {
  //   if (outletID) {
  //     getMenuData(outletID);
  //   }

  // }, [outletID]);

  useEffect(() => {
    getMenuData("6226e74f7a33be9c6a848a1d");
  }, []);

  const searchRef = useRef();
  const navigate = useNavigate();

  const menulocation = useLocation();

  useEffect(() => {
    if (!menulocation.state) {
      //
    } else {
      setMenuType(menulocation.state.menu);
    }
  }, [menulocation]);

  //Filtering Menu

  useEffect(() => {
    addFilteredSelectedItem();
    // console.log("selctedItem", selectedItem);
    // selectedItem.variation.map((variation) => console.log(variation.qty));
  }, [selectedItem]);

  // let scrollYPosition = window.scrollY;

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [menuType]);

  const addFilteredSelectedItem = () => {
    let newObject = { ...selectedItem };
    for (const key in newObject) {
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "addOns") {
          const element = newObject[key].filter((items) => items.qty > 0);
          newObject = {
            ...newObject,
            addOns: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "variation") {
          const element = newObject[key].filter(
            (items) => items.qty > 0 || items.qty !== undefined
          );
          newObject = {
            ...newObject,
            variation: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
    }

    // console.log("filtered data:", newObject);
    setFilterSelectedItem(newObject);
    // setSelectedItem(newObject); need to filter selected items
  };

  function searchMenu(e) {
    const searchTerm = searchRef.current.value;

    const params = { q: searchTerm };

    e.preventDefault();

    if (searchTerm) {
      navigate({
        pathname: "/search",
        search: `?${createSearchParams(params)}`,
      });
    }

    return null;
  }

  return (
    <>
      <MetaData title={"Menu"} />{" "}
      {!menuData ? (
        <div style={{ height: "80vh", display: "grid", placeItems: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="container">
          <div className="sticky-nav">
            <div className="menu-head content">
              {/* Toggle Menu Type */}
              <div className="menu-toggle">
                <button
                  className={
                    menuType == "Beverages" ? "primary" : "tertiary null"
                  }
                  onClick={() => {
                    setMenuType("Beverages");
                  }}
                >
                  Beverages
                </button>
                <button
                  className={menuType == "Food" ? "primary" : "tertiary null"}
                  onClick={() => {
                    setMenuType("Food");
                  }}
                >
                  Food
                </button>
                <button
                  className={
                    menuType == "Desserts" ? "primary" : "tertiary null"
                  }
                  onClick={() => {
                    setMenuType("Desserts");
                  }}
                >
                  Desserts
                </button>
              </div>

              {/* Search Bar */}
              <form className="searchContainer" onSubmit={searchMenu}>
                <input
                  ref={searchRef}
                  type="text"
                  className="search-right"
                  placeholder="Search food/drinks"
                />
                <div className="search" onClick={searchMenu}></div>
              </form>
            </div>
            <CategoryNav
              categories={
                menuType == "Food"
                  ? menuData.Food
                  : menuType == "Beverages"
                  ? menuData.Beverages
                  : menuData.Desserts
              }
            />
          </div>

          <div className="food-menu content">
            <CategorySection
              categories={
                menuType == "Food"
                  ? menuData.Food
                  : menuType == "Beverages"
                  ? menuData.Beverages
                  : menuData.Desserts
              }
              setModalOpen={setModalOpen}
              setSelectedItem={setSelectedItem}
            />
          </div>

          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            showButton={true}
          >
            <div className="scrollable">
              <ItemDetails
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                setIsVariationSelected={setIsVariationSelected}
              />
              <AddOnsDetails
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                isVariationSelected={isVariationSelected}
              />
            </div>
            {/* <button
              onClick={() => {
                /// TODO: need to add an error message if addons not loaded
                selectedItem.addOns === undefined
                  ? alert("Please Connect your internet")
                  : setCartItems((oldCartItems) => [
                      ...oldCartItems,
                      filterselectedItem,
                    ]);
                setModalOpen(false);
              }}
              className={
                isVariationSelected
                  ? "primary toCart"
                  : "primary toCart disabled"
              }
              disabled={isVariationSelected ? false : true}
            >
              Add to Cart
            </button> */}
          </Modal>
        </div>
      )}
    </>
  );
};

export default GJCMenu;
