import React from "react";

const CheckoutTotalItem = ({ totalitem }) => {
  return (
    <tr>
      <td>
        <span className="checkout-item-totals"> Total Items</span>
      </td>
      <td>
        <span className="checkout-item-totals"> {totalitem}</span>
      </td>
    </tr>
  );
};

export default CheckoutTotalItem;
