export const easing = "easeInOut";

export const animationContainer = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      easing: easing,
    },
  },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

export const fadeInUp = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

export const fadeInDown = {
  hidden: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

export const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: -80,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.2,
    },
  },
};

export const fadeInRight = {
  hidden: {
    opacity: 0,
    x: 80,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1.2,
    },
  },
};

export const zoomIn = {
  hidden: {
    scale: 0.8,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 1,
    },
  },
};

export const sentence = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.25,
      staggerChildren: 0.06,
    },
  },
};

export const letter = {
  hidden: {
    opacity: 0,
    y: 500,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

// export const smokeIn = {
//   hidden: {
//     opacity: 0,
//     y: -200,
//     rotate: 360,
//   },
//   visible: {
//     opacity: 1,
//     rotate: 0,
//     y: 0,
//   },
// };
