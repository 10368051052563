import React, { useState, useEffect } from "react";
import Button from "../../buttons/Button";
import Modal from "../../layouts/Modal";
import AddOnsDetails from "../../menu/AddOnsDetails";
import ItemDetails from "../../menu/ItemDetails";
import { useAppContext } from "../../../global/gjcContext";

const PopularItemCard = ({ item }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [filterselectedItem, setFilterSelectedItem] = useState({});
  const [isVariationSelected, setIsVariationSelected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { setCartItems } = useAppContext();
  const minifyString = (str, numberOFLetters) => {
    if (str.length > numberOFLetters)
      str = str.substring(0, numberOFLetters) + "...";

    return str;
  };

  useEffect(() => {
    addFilteredSelectedItem();
    // console.log("selctedItem", selectedItem);
    // selectedItem.variation.map((variation) => console.log(variation.qty));
  }, [selectedItem]);

  const addFilteredSelectedItem = () => {
    let newObject = { ...selectedItem };
    for (const key in newObject) {
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "addOns") {
          const element = newObject[key].filter((items) => items.qty > 0);
          newObject = {
            ...newObject,
            addOns: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "variation") {
          const element = newObject[key].filter(
            (items) => items.qty > 0 || items.qty !== undefined
          );
          newObject = {
            ...newObject,
            variation: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
    }

    // console.log("filtered data:", newObject);
    setFilterSelectedItem(newObject);
    // setSelectedItem(newObject); need to filter selected items
  };

  return (
    <>
      <div className="popular-item-card">
        <div className="popular-card__inner_container">
          <div className="image-wrapper">
            <img src={item.imgUrl} alt="food-image" />
          </div>
          <div className="content-wrapper">
            {item.name ? (
              <h3 className="title">{minifyString(item.name, 35)}</h3>
            ) : (
              ""
            )}

            <div className="divider"></div>
            {/* {item.details ? <p>{minifyString(item.details, 30)}</p> : ""} */}
            <div className="footer">
              <span className="price">&#2547; {item.variation[0].price}</span>
              <Button
                buttonText={"+"}
                buttonType="add-button"
                onClick={() => {
                  setModalOpen(true);
                  setSelectedItem(item);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        showButton={true}
      >
        <div className="scrollable">
          <ItemDetails
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setIsVariationSelected={setIsVariationSelected}
          />
          <AddOnsDetails
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            isVariationSelected={isVariationSelected}
          />
        </div>
        <button
          onClick={() => {
            /// TODO: need to add an error message if addons not loaded
            selectedItem.addOns === undefined
              ? alert("Please Connect your internet")
              : setCartItems((oldCartItems) => [
                  ...oldCartItems,
                  filterselectedItem,
                ]);
            setModalOpen(false);
          }}
          className={
            isVariationSelected ? "primary toCart" : "primary toCart disabled"
          }
          disabled={isVariationSelected ? false : true}
        >
          Add to Cart
        </button>
      </Modal>
    </>
  );
};

export default PopularItemCard;
