import React from "react";

import { motion } from "framer-motion";
import { animationContainer } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const TabNavbar = ({ activeTab, handleActive, outlets }) => {
  // console.log("active tab on change::", activeTab);
  const navAnimation = useAnimateOnInView();

  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.2,
      },
    },
  };

  return (
    <motion.nav
      ref={navAnimation.ref}
      animate={navAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className="tab-navbar"
    >
      {outlets.map((item, index) => (
        <motion.span
          variants={fadeInUp}
          onClick={() => {
            handleActive(index);
          }}
          key={index}
          className={`tab-nav-item ${activeTab === index ? "active" : ""}`}
        >
          {item.name}
        </motion.span>
      ))}
    </motion.nav>
  );
};

export default TabNavbar;

// const tabItems = [
//   { name: "gulshan-1", id: 1 },
//   { name: "gulshan-2", id: 2 },
//   { name: "badda", id: 3 },
//   { name: "dhanmondi", id: 4 },
// ];
