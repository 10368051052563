import React, { useState, useEffect, useMemo } from "react";
import Devider from "./table-divider";
import { useAppContext } from "../../global/gjcContext";

const CheckoutDeliveryAddress = () => {
  const {
    selectedDeliveryLocation,
    deliveryLocations,
    deliveryAddressInfo,
    setDeliveryAddressInfo,
    currentUser,
    saveThisAddress,
    setSaveThisAddress,
  } = useAppContext();

  const [savedAddressIndex, setSavedAddressIndex] = useState(0);

  // find areacode from deliveryLocations
  let deliveryAreaCode =
    deliveryLocations && deliveryLocations[selectedDeliveryLocation]._id;
  //find area name from deliveryLocations
  let deliveryAreaName =
    deliveryLocations && deliveryLocations[selectedDeliveryLocation].name;
  // filtering saved address according to selected delivery location
  const savedAddress = useMemo(
    () =>
      currentUser.savedAddress.length > 0 &&
      currentUser.savedAddress.filter(
        (address) => address.area && address.area._id == deliveryAreaCode
      ),
    [currentUser.savedAddress, deliveryAreaCode]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDeliveryAddressInfo({ ...deliveryAddressInfo, [name]: value });
  };

  const handlesavedAddressIndex = (event) => {
    setSavedAddressIndex(event.target.value);
  };

  useMemo(() => {
    if (savedAddress.length > 0) {
      setDeliveryAddressInfo({
        ...savedAddress[savedAddressIndex],
      });
    } else {
      setDeliveryAddressInfo({
        name: "",
        house: "",
        road: "",
        sector: "",
        area: "",
        direction: "",
      });
    }
  }, [savedAddress, savedAddressIndex]);

  // find area name from deliveryLocations of dileveryAddressInfo.area
  const areaName = useMemo(
    () =>
      deliveryLocations &&
      deliveryLocations.find(
        (location) => location._id === deliveryAddressInfo.area
      ),
    [deliveryLocations, deliveryAddressInfo.area]
  );

  // useEffect(() => {
  //   console.log("saveThisAddress:", saveThisAddress);
  // }, [
  //   deliveryAddressInfo,
  //   savedAddressIndex,
  //   currentUser.savedAddress,

  //   saveThisAddress,
  // ]);

  return (
    <>
      <tr>
        <td colSpan={2}>
          <div className="area-selection">
            <span>Delivery Information</span>

            <select
              name="address"
              id="address"
              value={savedAddressIndex}
              onChange={handlesavedAddressIndex}
              style={{ border: "1px solid transparent" }}
            >
              {savedAddress.length > 0 &&
                savedAddress.map((location, index) => (
                  <option key={index} value={parseInt(index)}>
                    {location.name}
                  </option>
                ))}
            </select>
          </div>
        </td>
      </tr>
      <Devider />
      <tr>
        <td colSpan={2}>
          <div className="card-credential-wrapper">
            <div className="area">
              <span>Area</span>

              <span>
                {savedAddress.length > 0
                  ? areaName && areaName.name
                  : deliveryAreaName}
              </span>
            </div>
            <label className="" htmlFor="house">
              <input
                type="text"
                id="house"
                name="house"
                placeholder="House"
                required
                value={deliveryAddressInfo.house}
                onChange={handleChange}
              />
            </label>
            <label className="" htmlFor="road">
              <input
                type="text"
                id="road"
                name="road"
                placeholder="Road"
                value={deliveryAddressInfo.road}
                onChange={handleChange}
                required
              />
            </label>
            <label className="">
              <input
                type="text"
                placeholder="Sector/Block"
                name="sector"
                value={deliveryAddressInfo.sector}
                onChange={handleChange}
              />
            </label>
            <label className="">
              <input
                type="text"
                name="direction"
                placeholder="(Any relatable info to find the location easily) "
                value={deliveryAddressInfo.direction}
                onChange={handleChange}
                style={{ borderBottom: "1px solid transparent" }}
              />
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <label htmlFor="savedThisAddress">
            <input
              type="checkbox"
              name="savedThisAddress"
              id="savedThisAddress"
              checked={saveThisAddress}
              onChange={(e) => {
                setSaveThisAddress(e.target.checked);
              }}
            />{" "}
            Save this address
          </label>
        </td>
      </tr>
    </>
  );
};

export default CheckoutDeliveryAddress;
