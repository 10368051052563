import React from "react";
import CartAddonsItem from "./cart-addons-item";
import CartHeader from "./cart-header";
import CartVariationItem from "./cart-variation-item";

const CartItem = ({
  items,
  item_index,
  handleOnclickChecked,
  handleOnclickUnChecked,
  increaseVariationQty,
  increaseAddonsQty,
  decreaseAddonsQty,
  decreaseVariationQty,
}) => {
  return (
    <>
      <CartHeader
        items={items}
        item_index={item_index}
        handleOnclickChecked={handleOnclickChecked}
        handleOnclickUnChecked={handleOnclickUnChecked}
      />
      <tr>
        <td colSpan={3}>
          <div className="cart-item-category-label">Size</div>
        </td>
      </tr>
      {items.variation.map((variation, variation_index) => (
        <CartVariationItem
          key={variation_index}
          variation={variation}
          variation_index={variation_index}
          increaseVariationQty={increaseVariationQty}
          decreaseVariationQty={decreaseVariationQty}
          item_index={item_index}
        />
      ))}
      {items.addOns.length > 0 ? (
        <tr>
          <td>
            <div className="cart-item-category-label">Add-Ons</div>
          </td>
        </tr>
      ) : (
        ""
      )}
      {items.addOns.length > 0
        ? items.addOns.map((addon, addon_index) => (
            <CartAddonsItem
              key={addon_index}
              addon={addon}
              addon_index={addon_index}
              item_index={item_index}
              items={items}
              increaseAddonsQty={increaseAddonsQty}
              decreaseAddonsQty={decreaseAddonsQty}
            />
          ))
        : ""}
    </>
  );
};

export default CartItem;
