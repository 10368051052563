import React from "react";
import Button from "../buttons/Button";
const WarningOutletChange = ({ handleOrderType, onClose }) => {
  return (
    <div className="order-type-modal-cntr">
      <h3>Are you sure ?</h3>
      <p>All your cart items will be deleted.</p>
      <div className="ordertype-change-action-btn">
        <Button
          onClick={handleOrderType}
          buttonText={"Yes"}
          buttonType="outlined"
        />
        <Button onClick={onClose} buttonText={"No"} buttonType="contained" />
      </div>
    </div>
  );
};

export default WarningOutletChange;
