import React, { useContext, useState } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { OurGjcContext } from "../../global/gjcContext";
import Button from "../buttons/Button";
import WarningOutletChange from "./WarningOutletChange";
import Modal from "./Modal";
const OrderType = ({ onClose }) => {
  const [modalOpen, setModalOpen] = useState(false);

  // const [orderType, setOrderType] = useState('Delivery');
  // taking essential context elements from OurGjcContext
  const {
    outlets,
    selectedOutlet,
    outletChangeHandler,
    orderType,
    setOrderType,
    cartItems,
    setCartItems,
    setcheckoutCartItems,
    orderTypeChangeHandler,
    deliveryLocations,
    selectedDeliveryLocation,
    deliveryLocationChangeHandler,
  } = useContext(OurGjcContext);
  const [ordertype, setOrdertype] = useState(orderType);
  const handleOrderType = () => {
    orderTypeChangeHandler(ordertype);
    setModalOpen(false);
    setCartItems([]);
    setcheckoutCartItems([]);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        showButton={true}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <WarningOutletChange
          handleOrderType={handleOrderType}
          onClose={() => {
            setModalOpen(false);
          }}
        />
        {/* <div className="order-type-modal-cntr">
          <h3>Are you sure ?</h3>
          <p>All your cart items will be deleted.</p>
          <div className="ordertype-change-action-btn">
            <Button
              onClick={handleOrderType}
              buttonText={"Yes"}
              buttonType="outlined"
            />
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
              buttonText={"No"}
              buttonType="contained"
            />
          </div>
        </div> */}
      </Modal>
      <div className="orderTypeModal scrollable">
        <div className="orderTypeContainer">
          <div
            className="pickup"
            onClick={() => {
              if (orderType !== "pickup" && cartItems.length > 0) {
                setModalOpen(true);
                setOrdertype("pickup");
              } else {
                orderTypeChangeHandler("pickup");
              }
            }}
          >
            <img src="/images/pickup.svg" alt="pickup" />
            <h3>Pick-up</h3>
          </div>
          <div
            className="delivery"
            onClick={() => {
              if (orderType !== "delivery" && cartItems.length > 0) {
                setModalOpen(true);
                setOrdertype("delivery");
              } else {
                orderTypeChangeHandler("delivery");
              }
            }}
          >
            <img src="/images/delivery.svg" alt="delivery" />
            <h3>Delivery</h3>
          </div>
        </div>

        <div className="orderTypeTabs">
          {orderType == "delivery" ? (
            <div className="deliveryTab">
              <p>
                Choose an area from our list where you want to deliver your
                food/drink
              </p>
              <select
                onChange={(e) => {
                  if (orderType === "delivery" && cartItems.length > 0) {
                    setModalOpen(true);
                  } else {
                    deliveryLocationChangeHandler(e.target.value);
                  }
                }}
                value={selectedDeliveryLocation}
              >
                {deliveryLocations &&
                  deliveryLocations.map((deliveryLocation, i) => (
                    <>
                      <option value={i}>{deliveryLocation.name}</option>
                    </>
                  ))}
              </select>
            </div>
          ) : (
            <div className="pickupTab">
              <p>
                Choose an outlet near you where you want to collect your
                food/drink
              </p>
              <select
                name="outletSelector"
                id="outletSelector"
                onChange={(e) => {
                  if (orderType === "pickup" && cartItems.length > 0) {
                    setModalOpen(true);
                  } else {
                    outletChangeHandler(e.target.value);
                  }
                }}
                value={selectedOutlet}
              >
                {outlets &&
                  outlets.map((outlet, i) => (
                    <>
                      <option value={i}>{outlet.name}</option>
                    </>
                  ))}
              </select>
            </div>
          )}
        </div>
        <button className="primary continueOrderType" onClick={onClose}>
          Continue
        </button>
      </div>
    </>
  );
};

export default OrderType;
