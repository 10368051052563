//// incrementing  qty of items in cart addons
export const increaseAddons = (cartItems, item_index, addon_index) => {
  let newarr = [...cartItems];
  // console.log(newarr[i]);
  newarr[item_index].addOns[addon_index].qty += 1;
  return newarr;
};

//// incrementing  qty of items in cart addons

export const decreaseAddons = (cartItems, item_index, addon_index) => {
  let newarr = [...cartItems];
  newarr[item_index].addOns[addon_index].qty -= 1;
  return newarr;
};
